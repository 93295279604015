import { type FC } from "react"
import { useParams } from "react-router"
import styled from "styled-components"
import tinycolor from "tinycolor2"

import { parseNumber } from "@forento/shared/utilities/number"

import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { dangerColor, successColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

const EnrolledStudentsPage: FC = () => {
	const courseId = parseNumber(useParams().courseId)

	const { data, error } = query.course.listEnrolledStudents.useQuery(courseId ?? -1)

	return (
		<Layout>
			<PageBreadcrumb
				title="Enrolled students"
				path={[
					{ title: "Courses", link: routes.course.index() },
					{
						title: data?.course.title ?? "...",
						link: data ? routes.course.detail(data.course.id) : undefined,
					},
				]}
			/>
			{error ? (
				<p>Failed to load data.</p>
			) : data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Container>
					{[...data.users]
						.sort((a, b) => (b.progressPercent ?? 0) - (a.progressPercent ?? 0))
						.map(user => (
							<User key={user.id}>
								<Email>{user.email}</Email>
								<Name>
									{user.firstName} {user.lastName}
								</Name>
								<Tags>
									{user.unlockedReason !== "free" && (
										<Tag $color={successColor}>
											{user.unlockedReason === "purchased"
												? "Purchased"
												: user.unlockedReason === "membership"
													? "Membership"
													: user.unlockedReason === "expired-membership"
														? "Expired membership"
														: user.unlockedReason === "bundle"
															? "Bundle"
															: "Unknown"}
										</Tag>
									)}
									<Tag $color={dangerColor}>
										{user.progressPercent !== null ? `${user.progressPercent}%` : "Not started"}
									</Tag>
								</Tags>
							</User>
						))}
				</Container>
			)}
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const User = styled.div`
	background-color: #fbfbf9;
	border: 1px solid #eaeaea;
	border-radius: 12px;
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`

const Email = styled.div`
	flex: 0 0 300px;
	font-weight: 600;
	font-size: 14px;
`

const Name = styled.p`
	flex: 1 0 0;
	font-weight: 600;
	font-size: 14px;
`

const Tags = styled.div`
	display: flex;
	gap: 16px;
`

const Tag = styled.div<{ $color: string }>`
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
	color: ${props => props.$color};
	background-color: ${props => tinycolor(props.$color).setAlpha(0.05).toString()};
	border-radius: 12px;
	padding: 6px 12px;
`

export default EnrolledStudentsPage
