import { type FC } from "react"
import styled, { css } from "styled-components"

import Button from "~/components/Button"
import { CheckmarkIcon } from "~/components/Icon"
import { primaryColor } from "~/utilities/styles"

type Props = {
	order: number
	title: string
	callToAction: string
	onClick: string
	isCompleted: boolean
}

const Task: FC<Props> = ({ order, title, callToAction, onClick, isCompleted }) => (
	<Container>
		<Order>{isCompleted ? <StyledCheckmarkIcon /> : order}</Order>
		<Title $isCompleted={isCompleted}>{title}</Title>
		{!isCompleted && (
			<CallToAction onClick={onClick}>
				{callToAction}
				<StyledChevronIcon />
			</CallToAction>
		)}
	</Container>
)

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	font-weight: 500;
	font-size: 16px;
`

const Order = styled.div`
	flex: 0 0 auto;
	width: 32px;
	height: 32px;
	background-color: #e8e4e0;
	font-weight: 600;
	font-size: 14px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Title = styled.p<{ $isCompleted: boolean }>`
	flex: 1 0 0;
	font-weight: 600;
	font-size: 16px;
	${props =>
		props.$isCompleted &&
		css`
			text-decoration: line-through;
		`}
`

const CallToAction = styled(Button)`
	flex: 0 0 auto;
	font-weight: 600;
	font-size: 14px;
	color: ${primaryColor};
	display: flex;
	align-items: center;
	gap: 6px;
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
	width: 24px;
	height: 24px;
`

const StyledChevronIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="14" height="14">
		<path d="M18.75 36 16.6 33.85 26.5 23.95 16.6 14.05 18.75 11.9 30.8 23.95Z" />
	</svg>
)

export default Task
