import dayjs from "dayjs"
import { type FC, useLayoutEffect, useState } from "react"

import { toggleStateArray } from "@forento/shared/utilities/array"
import { getDataUrlByFile, getFileNameFromPath } from "@forento/shared/utilities/file"
import { toNullIfEmpty } from "@forento/shared/utilities/string"

import Button, { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import ImageUploader from "~/components/ImageUploader"
import InputField, { InputTextArea, useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import usePrice from "~/hooks/usePrice"
import trpc, { query } from "~/utilities/trpc"

const EditEventModal: FC<{ eventId: number | null; onClose(): void }> = ({ eventId, onClose }) => {
	const alert = useAlert()

	const { data: event, error } = query.event.get.useQuery(eventId ?? -1, { refetchOnWindowFocus: false })

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description", initialValue: event?.longDescription })
	const [thumbnail, setThumbnail] = useState<File | null>(null)
	const [startDate, setStartDate] = useState(dayjs())
	const [endDate, setEndDate] = useState(dayjs())
	const [isPaid, setPaid] = useState(false)
	const { price, setPrice, priceValue } = usePrice()
	const [location, setLocation] = useState("")
	const [videoCallLink, setVideoCallLink] = useState("")
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [isSubmitting, setSubmitting] = useState(false)

	const memberships = query.membership.list.useQuery(undefined, { enabled: eventId !== null })

	const thumbnailFilePath = thumbnail ? URL.createObjectURL(thumbnail) : null

	const isInputValid = title.trim().length > 0
	const isLoading = eventId !== null && (event?.id !== eventId || (event === undefined && !error))

	useLayoutEffect(() => {
		if (event == null || eventId === null) return
		if (event.id !== eventId) return

		const abortController = new AbortController()

		setTitle(event.title)
		setShortDescription(event.shortDescription ?? "")
		setStartDate(dayjs(event.startDate))
		setEndDate(dayjs(event.endDate))
		setPaid(event.price !== null)
		setPrice(event.price?.amount.toString() ?? "")
		setLocation(event.location ?? "")
		setVideoCallLink(event.videoCallLink ?? "")
		setMembershipIds(event.memberships)

		if (event.thumbnailFilePath) {
			fetch(event.thumbnailFilePath, { signal: abortController.signal })
				.then(async x => ({
					name: getFileNameFromPath(x.url),
					blob: await x.blob(),
				}))
				.then(({ name, blob }) => {
					if (abortController.signal.aborted) return
					setThumbnail(new File([blob], name ?? "Thumbnail", { type: blob.type }))
				})
		} else {
			setThumbnail(null)
		}

		return () => {
			abortController.abort()
		}
	}, [eventId, event, setPrice])

	const handleSubmit = async () => {
		if (!isInputValid || eventId === null) return

		if (startDate > endDate) {
			await alert.show("Invalid date", "The start date must be before the end date.")
			return
		}

		setSubmitting(true)
		try {
			await trpc.event.update.mutate({
				id: eventId,
				data: {
					title: title.trim(),
					shortDescription,
					longDescription: longDescription.exportEditorState(),
					thumbnailDataUrl: thumbnail ? await getDataUrlByFile(thumbnail) : null,
					startDate: startDate.toDate(),
					endDate: endDate.toDate(),
					price: isPaid ? priceValue : null,
					location: toNullIfEmpty(location.trim()) ?? null,
					videoCallLink: toNullIfEmpty(videoCallLink.trim()) ?? null,
					membershipIds,
				},
			})
			onClose()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={eventId !== null} onSubmit={handleSubmit}>
			<ModalTitle>Edit event</ModalTitle>
			{error || memberships.error ? (
				<>
					<ModalText>Failed to load event.</ModalText>
					<ModalButtons>
						<Button variant="secondary" onClick={onClose}>
							Close
						</Button>
					</ModalButtons>
				</>
			) : isLoading || !memberships.data ? (
				<>
					<PartialLoadingPage />
					<ModalButtons>
						<Button variant="secondary" onClick={onClose}>
							Close
						</Button>
					</ModalButtons>
				</>
			) : (
				<>
					<ModalInputs>
						<InputField label="Title *" value={title} onChange={setTitle} />
						<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
						{longDescription.element}
						<div>
							<InputLabel>Thumbnail</InputLabel>
							<ImageUploader
								filePath={thumbnailFilePath}
								onUpload={setThumbnail}
								onDelete={() => setThumbnail(null)}
							/>
						</div>
						<InputField
							inputType="datetime-local"
							label="Start date *"
							value={startDate.format("YYYY-MM-DD HH:mm")}
							onChange={value => setStartDate(dayjs(value))}
						/>
						<InputField
							inputType="datetime-local"
							label="End date *"
							value={endDate.format("YYYY-MM-DD HH:mm")}
							onChange={value => setEndDate(dayjs(value))}
						/>
						<div>
							<Checkbox label="Is paid" isChecked={isPaid} onChange={setPaid} />
							<InputField
								label="Price"
								value={price.toString()}
								inputType="number"
								onChange={setPrice}
								disabled={!isPaid}
							/>
						</div>
						<InputTextArea label="Location" value={location} onChange={setLocation} />
						<InputField label="Video call link" value={videoCallLink} onChange={setVideoCallLink} />
						{memberships.data.length > 0 && (
							<div>
								<InputLabel>Include in memberships</InputLabel>
								{memberships.data.map(membership => (
									<Checkbox
										label={membership.title}
										isChecked={membershipIds.includes(membership.id)}
										onChange={() => toggleStateArray(setMembershipIds, membership.id)}
									/>
								))}
							</div>
						)}
					</ModalInputs>
					<ModalButtons>
						<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
							Cancel
						</Button>
						<SubmitButton variant="primary" isLoading={isSubmitting} isDisabled={!isInputValid}>
							Save event
						</SubmitButton>
					</ModalButtons>
				</>
			)}
		</DefaultModal>
	)
}

export default EditEventModal
