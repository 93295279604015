import { type FC } from "react"
import { Navigate, useParams } from "react-router"

import { toDateTimeString, toTimeIntervalString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"

import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const ExamCompletionsPage: FC = () => {
	const examId = parseNumber(useParams().examId) ?? -1

	const { data: exam, error } = query.exam.get.useQuery(examId)

	if (exam === null) return <Navigate to={routes.exam.index()} />

	return (
		<Layout>
			<PageBreadcrumb
				path={[
					{ title: "Exams", link: routes.exam.index() },
					{ title: exam?.name ?? "...", link: exam !== undefined ? routes.exam.detail(exam.id) : undefined },
				]}
				title="Completions"
			/>
			{error ? (
				<p>Failed to load exam.</p>
			) : exam === undefined ? (
				<PartialLoadingPage />
			) : (
				<Table
					columns={[
						"Email",
						"Name",
						{ label: "Score", flex: { basis: 50, grow: 0 } },
						{ label: "Complete date", flex: { basis: 150, grow: 0 } },
						{ label: "Time taken", flex: { basis: 100, grow: 0 } },
					]}
					data={exam.completions.map(completion => [
						completion.user.email,
						`${completion.user.firstName} ${completion.user.lastName}`,
						`${completion.score}/${exam.questions.length}`,
						toDateTimeString(completion.completeDate),
						toTimeIntervalString(
							(completion.completeDate.getTime() - completion.startDate.getTime()) / 1000,
						),
					])}
				/>
			)}
		</Layout>
	)
}

export default ExamCompletionsPage
