import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type FC } from "react"
import styled, { type CSSProperties } from "styled-components"

import { type IntendedPageType } from "@forento/shared/models/course"

import Button from "~/components/Button"
import {
	CustomIcon,
	DragIndicatorIcon,
	type Icon,
	InputIcon,
	QuizIcon,
	TextIcon,
	VideoIcon,
	VimeoIcon,
	YouTubeIcon,
} from "~/components/Icon"

const icons: Record<IntendedPageType, Icon> = {
	video: VideoIcon,
	youtube: YouTubeIcon,
	vimeo: VimeoIcon,
	quiz: QuizIcon,
	input: InputIcon,
	text: TextIcon,
	custom: CustomIcon,
}

type Props = {
	page: { id: number; title: string; intendedType: IntendedPageType }
	order: number
	onClick: () => void
}
const Page: FC<Props> = ({ page, order, onClick }) => {
	const { setNodeRef, attributes, listeners, transform, transition } = useSortable({ id: page.id })

	const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition }

	return (
		<Container style={style} ref={setNodeRef}>
			<Clickable onClick={onClick}>
				<Number>{order + 1}</Number>
				<StyledTypeIcon as={icons[page.intendedType]} />
				<Title>{page.title}</Title>
			</Clickable>
			<DragIndicator {...attributes} {...listeners} />
		</Container>
	)
}

const Container = styled.li`
	width: 100%;
	border-bottom: 1px solid #e1e1e1;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Clickable = styled(Button)`
	flex: 1;
	display: flex;
	align-items: center;
	padding: 4px 0;
`

const Number = styled.p`
	font-weight: 600;
	font-size: 16px;
	margin-right: 16px;
`

const StyledTypeIcon = styled.div`
	width: 18px;
	height: 18px;
	margin-right: 6px;
`

const Title = styled.p`
	font-weight: 500;
	font-size: 16px;
	flex: 1;
`

const DragIndicator = styled(DragIndicatorIcon)`
	width: 24px;
	height: 24px;
	cursor: grab;
`

export default Page
