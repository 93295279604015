import { type FC } from "react"
import styled from "styled-components"

import { type ChapterDripInterval } from "@forento/shared/models/course"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import FeatureIntroduction from "~/components/FeatureIntroduction"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

import illustration from "./illustration.svg"

const chapterDripOptions: { id: ChapterDripInterval | "none"; title: string }[] = [
	{ id: "none", title: "Release all instantly" },
	{ id: "day", title: "One chapter per day" },
	{ id: "week", title: "One chapter per week" },
	{ id: "month", title: "One chapter per month" },
]

type Props = { interval: ChapterDripInterval | null; onChange(interval: ChapterDripInterval | null): void }
const ChapterDripping: FC<Props> = ({ interval, onChange }) => {
	const platform = usePlatform()!

	return (
		<Container>
			<FeatureIntroduction
				illustrationUrl={illustration}
				title="Chapter dripping"
				text="Enable this option to activate the dripping feature for course chapters. This allows you to release course content gradually over time, ensuring that users receive new chapters at a controlled pace. This method helps maintain engagement and allows users to absorb information more effectively."
			>
				{platform.plan.access.chapterDripping ? (
					<>
						<StyledDropdown
							items={chapterDripOptions}
							selectedItemId={interval ?? "none"}
							onChange={value => {
								const parsedValue = chapterDripOptions.find(option => option.id === value)?.id ?? "none"
								onChange(parsedValue !== "none" ? parsedValue : null)
							}}
						/>
						<Button variant="link" onClick={routes.settings.studentEmail.index()}>
							Customize chapter dripping student e-mail
						</Button>
					</>
				) : (
					<>
						<Text>You need to upgrade your plan to access this feature.</Text>
						<Button variant="primary" onClick={routes.settings.billing.plans()}>
							Browse plans
						</Button>
					</>
				)}
			</FeatureIntroduction>
		</Container>
	)
}

const Container = styled.div`
	margin-top: 24px;
`

const StyledDropdown = styled(Dropdown)`
	width: 100%;
	max-width: 250px;
`

const Text = styled.p`
	color: ${lightTextColor};
	text-align: center;
	max-width: 500px;
	font-style: italic;
`

export default ChapterDripping
