import { type FC, useState } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

import Button from "~/components/Button"
import { DeleteIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import { dangerColor } from "~/utilities/styles"
import trpc, { query } from "~/utilities/trpc"

import { Description, Title } from "../styles"
import AddTesterModal from "./AddTesterModal"

const Testers: FC<{ courseId: number }> = ({ courseId }) => {
	const alert = useAlert()

	const { data: testers, error, refetch } = query.course.listTesters.useQuery(courseId)

	const [isAddTesterModalOpen, setAddTesterModalOpen] = useState(false)
	const [removingTesterId, setRemovingTesterId] = useState<number>()

	if (error) return <p>Failed to load testers.</p>
	if (testers === undefined) return <PartialLoadingPage />

	const handleRemoveTester = async (testerUserId: number) => {
		setRemovingTesterId(testerUserId)
		try {
			await trpc.course.removeTester.mutate({ courseId, userId: testerUserId })
			await refetch()
		} catch {
			console.error("Failed to remove tester.")
			await alert.show("Error", "Failed to remove tester. Please try again later.")
		} finally {
			setRemovingTesterId(undefined)
		}
	}

	return (
		<Container>
			<AddTesterModal
				courseId={courseId}
				isOpen={isAddTesterModalOpen}
				onClose={() => {
					setAddTesterModalOpen(false)
					refetch()
				}}
			/>
			<Title>Testers</Title>
			<Description>
				Testers receive free access to your course as well as access to unpublished courses.
			</Description>
			<StyledTable
				columns={["Email", "Name", "Actions"]}
				data={testers.map(tester => [
					tester.email,
					`${tester.firstName} ${tester.lastName}`,
					<Actions>
						<Tooltip tooltip="Remove tester">
							<RemoveButton
								onClick={() => handleRemoveTester(tester.userId)}
								isLoading={removingTesterId !== undefined}
								replaceOnLoading
							>
								<DeleteIcon />
							</RemoveButton>
						</Tooltip>
					</Actions>,
				])}
			/>
			<AddButton onClick={() => setAddTesterModalOpen(true)}>Add tester</AddButton>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const StyledTable = styled(Table)`
	margin-bottom: 16px;
`

const Actions = styled.div`
	display: flex;
`

const RemoveButton = styled(Button)`
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

const AddButton = styled(Button).attrs({ variant: "primary" })`
	align-self: flex-start;
`

export default Testers
