import dayjs from "dayjs"
import { useMemo, useState } from "react"
import styled from "styled-components"

import { type AnalyticsInformation } from "@forento/shared/models/analytics"
import { getFirstDayOfWeek } from "@forento/shared/utilities/date"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import { SalesIcon, UsersIcon } from "~/components/Icon"
import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { cardColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

import Chart from "./Chart"
import { type ChartCategory } from "./ChartCategorySelector"
import Devices from "./Devices"
import PeriodSelector, { type Period, periodOptions } from "./PeriodSelector"
import Sources from "./Sources"
import StatCard from "./StatCard"

type ChartInterval = "daily" | "monthly"

function getNormalizedChartData(selectedCategory: ChartCategory, data: AnalyticsInformation) {
	switch (selectedCategory) {
		case "sales":
			return {
				label: "sales",
				unit: data.sales.days[0]?.currency ?? "USD",
				values: data.sales.days.map(x => ({ date: dayjs(x.date), value: x.amount })),
			}
		case "visitors":
			return {
				label: "visitors",
				values: data.visits.days.map(x => ({ date: dayjs(x.date), value: x.visits })),
			}
		case "unique-visitors":
			return {
				label: "unique visitors",
				values: data.visits.days.map(x => ({ date: dayjs(x.date), value: x.uniqueVisitors })),
			}
	}
}

function getDataSpecificChartProps(
	data: AnalyticsInformation,
	category: ChartCategory,
	interval: ChartInterval,
): { label: string; unit?: string; values: { name: string; value: number }[] } {
	const analytics = getNormalizedChartData(category, data)

	switch (interval) {
		case "daily":
			return {
				...analytics,
				values: analytics.values.map(value => ({ name: value.date.format("DD/MM"), value: value.value })),
			}
		case "monthly":
			return {
				...analytics,
				values: analytics.values.reduce<{ name: string; value: number }[]>((value, current) => {
					const existing = value.find(x => x.name === current.date.format("YYYY-MM"))
					return [
						...value.filter(x => x.name !== existing?.name),
						{ name: current.date.format("YYYY-MM"), value: (existing?.value ?? 0) + current.value },
					]
				}, []),
			}
	}
}

const AnalyticsPage: React.FC = () => {
	const [selectedPeriod, setSelectedPeriod] = useState<Period>("this-week")
	const [selectedChartCategory, setSelectedChartCategory] = useState<ChartCategory>("sales")

	const periodStartDate = useMemo(() => {
		switch (selectedPeriod) {
			case "today":
				return dayjs()
			case "this-week":
				return dayjs(getFirstDayOfWeek())
			case "one-week":
				return dayjs().subtract(7, "day")
			case "this-month":
				return dayjs(dayjs().format("YYYY-MM-01"))
			case "one-month":
				return dayjs().subtract(1, "month")
			case "this-year":
				return dayjs(dayjs().format("YYYY-01-01"))
			case "one-year":
				return dayjs().subtract(1, "year")
			case "all-time":
				return null
			default:
				exhaustiveGuard(selectedPeriod)
		}
	}, [selectedPeriod])

	const { data, error } = query.analytics.get.useQuery({ startDate: periodStartDate?.format("YYYY-MM-DD") ?? null })

	const selectedChartInterval: ChartInterval = (() => {
		if (["today", "this-week", "one-week", "this-month", "one-month"].includes(selectedPeriod)) return "daily"
		else return "monthly"
	})()

	return (
		<Layout>
			<PageTitle>Analytics</PageTitle>
			<PeriodSelector selectedValue={selectedPeriod} onChange={setSelectedPeriod} />
			{error ? (
				<Error>Failed to load analytics.</Error>
			) : data === undefined ? (
				<PartialLoadingPage />
			) : data.visits === null ? (
				<Error>
					Analytics can't be loaded right now. Usually this is because of routine maintenance. Please check
					back soon again. We're sorry for the inconvenience.
				</Error>
			) : (
				<Content>
					<StatCard
						gridArea="sales"
						icon={SalesIcon}
						value={data.sales.total}
						unit={data.sales.days[0]?.currency ?? "USD"}
						label="Sales"
						color={cardColor.blue}
					/>
					<StatCard
						gridArea="visitors"
						icon={UsersIcon}
						value={data.visits.days.reduce((value, current) => value + current.visits, 0)}
						label="Visitors"
						color={cardColor.orange}
					/>
					<Chart
						{...getDataSpecificChartProps(
							{ ...data, visits: data.visits },
							selectedChartCategory,
							selectedChartInterval,
						)}
						periodTitle={periodOptions.find(p => p.id === selectedPeriod)?.label ?? "Total"}
						selectedChartCategory={selectedChartCategory}
						setSelectedChartCategory={setSelectedChartCategory}
					/>
					<Sources sources={data.visits.sources} />
					<Devices devices={data.visits.devices} />
				</Content>
			)}
		</Layout>
	)
}

const Error = styled.p`
	margin-top: 20px;
`

const Content = styled.div`
	display: grid;
	grid-column: 1fr 1fr 1fr;
	grid-template-areas: "sales visitors sources" "chart chart sources" "devices . .";
	gap: 20px;
	margin-top: 20px;
`

export default AnalyticsPage
