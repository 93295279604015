import { type FC, useState } from "react"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import { SendIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { toRelativeDateString } from "~/utilities/date"
import { primaryColor, primaryLightColor } from "~/utilities/styles"
import trpc, { query } from "~/utilities/trpc"

import Comment from "./Comment"

const PostDetail: FC<{ postId: number | null }> = ({ postId }) => {
	const post = query.community.getPost.useQuery(postId!, { enabled: postId !== null })
	const comments = query.community.listPostComments.useQuery(postId!, { enabled: postId !== null })

	const [comment, setComment] = useState("")

	const [isSubmittingComment, setSubmittingComment] = useState(false)

	const handleSubmitComment = async () => {
		if (post.data == null || comment.trim().length === 0) return

		setSubmittingComment(true)
		try {
			await trpc.community.createPostComment.mutate({ postId: post.data.id, text: comment.trim() })
			setComment("")
			await comments.refetch()
		} finally {
			setSubmittingComment(false)
		}
	}

	return (
		<Container>
			{postId === null ? null : post.error ? (
				<p>Failed to load post.</p>
			) : post.data === undefined ? (
				<PartialLoadingPage />
			) : post.data === null ? (
				<p>Post was not found.</p>
			) : (
				<PostContent>
					<Author>
						<CommunityProfileAvatar profile={post.data.profile} size={32} backgroundColor="white" />
						{post.data.profile.firstName} {post.data.profile.lastName}
						<Date title={toDateTimeString(post.data.createDate)}>
							{toRelativeDateString(post.data.createDate)}
						</Date>
					</Author>
					<Title>{post.data.title}</Title>
					<Content dangerouslySetInnerHTML={{ __html: post.data.htmlContent }} />
					{comments.error ? (
						<p>Failed to load comments.</p>
					) : comments.data === undefined ? (
						<PartialLoadingPage />
					) : (
						comments.data.length > 0 && (
							<Comments>
								{comments.data.map(comment => (
									<Comment key={comment.id} comment={comment} />
								))}
							</Comments>
						)
					)}
					<Input.Container>
						<Input.Field
							placeholder="Type your comment..."
							value={comment}
							onChange={event => setComment(event.target.value)}
						/>
						<Input.SubmitButton
							onClick={handleSubmitComment}
							isLoading={isSubmittingComment}
							replaceOnLoading
						>
							<Input.SubmitIcon />
						</Input.SubmitButton>
					</Input.Container>
				</PostContent>
			)}
		</Container>
	)
}

const Container = styled.div`
	grid-area: post-detail;
	flex: 1 0 0;
`

const PostContent = styled.div`
	background-color: ${primaryLightColor};
	padding: 20px;
	display: flex;
	flex-direction: column;
`

const Author = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	margin-bottom: 8px;
`

const Date = styled.div`
	color: #949494;
`

const Title = styled.h3`
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 8px;
`

const Content = styled.div`
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Comments = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 14px;
	margin-bottom: 16px;
`

const Input = {
	Container: styled.div`
		position: relative;
	`,
	Field: styled.input.attrs({ type: "text" })`
		width: 100%;
		font-size: 16px;
		color: black;
		line-height: 1;
		border: 1px solid #d0d5dd;
		border-radius: 8px;
		box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		padding: 16px 52px 16px 16px;
	`,
	SubmitButton: styled(Button)`
		position: absolute;
		top: 50%;
		right: 18px;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		color: ${primaryColor};
	`,
	SubmitIcon: styled(SendIcon)`
		width: 100%;
		height: 100%;
	`,
}

export default PostDetail
