import mime from "mime"
import papa from "papaparse"
import { type FC, useEffect, useState } from "react"
import readXlsxFile from "read-excel-file"
import styled from "styled-components"

import { toDistinctArray, toggleStateArray } from "@forento/shared/utilities/array"
import { parseNumber } from "@forento/shared/utilities/number"
import regex from "@forento/shared/utilities/regex"

import Button, { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import Dropdown from "~/components/Dropdown"
import FileUploader from "~/components/FileUploader"
import InputLabel from "~/components/InputLabel"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

type Props = { isOpen: boolean; onClose(): void }

const InviteUsersModal: FC<Props> = ({ isOpen, onClose }) => {
	const platform = usePlatform()
	const alert = useAlert()

	const [emails, setEmails] = useState<string[]>([])
	const [membershipId, setMembershipId] = useState<number>()
	const [courseIds, setCourseIds] = useState<number[]>([])
	const [isSubmitting, setSubmitting] = useState(false)

	const memberships = query.membership.list.useQuery(undefined, { enabled: isOpen })
	const courses = query.course.list.useQuery(undefined, { enabled: isOpen })

	useEffect(() => {
		if (memberships.data === undefined) {
			setMembershipId(undefined)
		}
	}, [membershipId, memberships.data])

	const isInputValid = emails.length > 0

	const handleUpload = async (file: File) => {
		const data: string[][] = await (async () => {
			if (file.type === mime.getType("csv")) {
				return papa
					.parse(await file.text(), { skipEmptyLines: "greedy" })
					.data.map(row => (row instanceof Object ? (Object.values(row) as string[]) : (row as string[])))
			} else {
				return (await readXlsxFile(file)).map(row => row.map(cell => cell.toString()))
			}
		})()

		if (data.length === 0) return

		if (data.some(row => row.length > 1)) {
			await alert.show("Invalid spreadsheet", "You need to have only one column in the spreadsheet with emails.")
			return
		}

		setEmails(
			toDistinctArray(
				data.map(row => row[0].trim().toLowerCase()).filter(row => row.length > 0 && regex.email.test(row)),
			),
		)
	}

	const handleSubmit = async () => {
		if (!isInputValid) return

		setSubmitting(true)
		try {
			await trpc.platform.inviteUsers.mutate({ emails, membershipId, courseIds })
			onClose()
			setEmails([])
			setCourseIds([])
		} finally {
			setSubmitting(false)
		}
	}

	if (platform!.customEmailSender === null) {
		return (
			<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
				<ModalTitle>Invite users</ModalTitle>
				<Content>
					<p>You need to connect a custom sender email address in order to invite users.</p>
				</Content>
				<ModalButtons>
					<Button variant="secondary" onClick={onClose}>
						Close
					</Button>
					<Button variant="primary" onClick={routes.settings.platform()}>
						Settings
					</Button>
				</ModalButtons>
			</DefaultModal>
		)
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Invite users</ModalTitle>
			<Content>
				{emails.length === 0 ? (
					<FileUploader type="spreadsheet" onUpload={handleUpload} />
				) : (
					<List>
						{emails.map(email => (
							<li key={email}>{email}</li>
						))}
					</List>
				)}
				{memberships.data !== undefined && memberships.data.length > 0 && (
					<div>
						<InputLabel>Default membership</InputLabel>
						<Dropdown
							items={[
								{ id: "none", title: "No default membership" },
								...memberships.data.map(membership => ({
									id: membership.id.toString(),
									title: membership.title,
								})),
							]}
							selectedItemId={membershipId?.toString() ?? "none"}
							onChange={value => {
								const id = parseNumber(value)
								setMembershipId(id !== null ? id : undefined)
							}}
						/>
					</div>
				)}
				{platform?.enabledFeatures.includes("invite-to-course") &&
					courses.data !== undefined &&
					courses.data.length > 0 && (
						<div>
							<InputLabel>Assign to courses</InputLabel>
							{courses.data.map(course => (
								<Checkbox
									key={course.id}
									label={course.title}
									isChecked={courseIds.includes(course.id)}
									onChange={() => toggleStateArray(setCourseIds, course.id)}
								/>
							))}
						</div>
					)}
			</Content>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isDisabled={!isInputValid} isLoading={isSubmitting}>
					Send invites
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

const Content = styled(ModalContentPadding)`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const List = styled.ol`
	margin-left: 1em;
`

export default InviteUsersModal
