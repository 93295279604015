import type { OutputData } from "@editorjs/editorjs"
import { type FC, useState, useEffect, useMemo, lazy, Suspense } from "react"
import { Navigate, useParams } from "react-router"

import { type ChapterDripInterval } from "@forento/shared/models/course"
import { toPriceString } from "@forento/shared/utilities/currency"
import { getDataUrlByFile, getFileNameFromPath } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import { ControlledTabs } from "~/components/Tabs"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { useUser } from "~/contexts/UserContext"
import usePrice from "~/hooks/usePrice"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import ChapterDripping from "./ChapterDripping"
import CourseInformation from "./CourseInformation"
import IssueCertificates from "./IssueCertificates"
import Testers from "./Testers"
import { Description, Title } from "./styles"

const CourseLongDescriptionEditor = lazy(() => import("./CourseLongDescriptionEditor"))

const CourseSettingsPage: FC = () => {
	const courseId = parseNumber(useParams().courseId) ?? -1
	const alert = useAlert()
	const toast = useToast()
	const platform = useUser().user!.platform!

	const { data: course, error: courseError, refetch: reloadCourse } = query.course.get.useQuery(courseId)
	const tags = query.course.listTags.useQuery()

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const [commentsEnabled, setCommentsEnabled] = useState(false)
	const [autoApproveComments, setAutoApproveComments] = useState(false)
	const [selectedTags, setSelectedTags] = useState<number[]>([])
	const [thumbnail, setThumbnail] = useState<File | null>(null)
	const [isThumbnailUpdated, setThumbnailUpdated] = useState(false)
	const [chapterDripInterval, setChapterDripInterval] = useState<ChapterDripInterval | null>(null)
	const [issueCertificates, setIssueCertificates] = useState(false)
	const { price, priceValue, setPrice } = usePrice()
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [longDescription, setLongDescription] = useState<{ isModified: boolean; value: OutputData } | null>()
	const [isLoaded, setLoaded] = useState(false)

	useEffect(() => {
		const abortController = new AbortController()

		if (course) {
			setTitle(course.title)
			setShortDescription(course.shortDescription)
			setCommentsEnabled(course.commentsEnabled)
			setAutoApproveComments(course.autoApproveComments)
			setSelectedTags(course.tags.map(tag => tag.id))
			setThumbnail(null)
			setThumbnailUpdated(false)
			setChapterDripInterval(course.chapterDripInterval)
			setIssueCertificates(course.issueCertificates)
			setLongDescription(course.longDescription !== null ? JSON.parse(course.longDescription) : null)
			setPrice(course.price?.amount.toString() ?? "")
			setMembershipIds(course.membershipIds)
			setLoaded(true)

			if (course.thumbnailFilePath) {
				fetch(course.thumbnailFilePath, { signal: abortController.signal })
					.then(async x => ({ name: getFileNameFromPath(x.url), blob: await x.blob() }))
					.then(({ name, blob }) => {
						if (abortController.signal.aborted) return
						setThumbnail(new File([blob], name ?? "Thumbnail", { type: blob.type }))
						setThumbnailUpdated(false)
					})
			}
		}

		return () => {
			abortController.abort()
		}
	}, [course, setPrice])

	useEffect(() => {
		const isModified =
			course &&
			isLoaded &&
			(title !== course.title ||
				shortDescription !== course.shortDescription ||
				commentsEnabled !== course.commentsEnabled ||
				autoApproveComments !== course.autoApproveComments ||
				selectedTags.join(",") !== course.tags.map(tag => tag.id).join(",") ||
				isThumbnailUpdated ||
				chapterDripInterval !== course.chapterDripInterval ||
				issueCertificates !== course.issueCertificates ||
				longDescription?.isModified ||
				priceValue !== (course?.price ? course.price.amount : null) ||
				membershipIds.join(",") !== course.membershipIds.join(","))

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				if (!course) return false
				if (priceValue !== null && priceValue < 1) {
					alert.show(
						"Error",
						`The price must be at least ${toPriceString({ amount: 1, currency: platform.currency })}.`,
					)
					return false
				}

				try {
					await trpc.course.update.mutate({
						id: course.id,
						data: {
							title,
							shortDescription,
							price: priceValue,
							membershipIds,
							commentsEnabled,
							autoApproveComments,
							chapterDripInterval,
							issueCertificates,
							longDescription: longDescription ? JSON.stringify(longDescription.value) : null,
							tags: selectedTags,
							thumbnailDataUrl: isThumbnailUpdated
								? thumbnail
									? await getDataUrlByFile(thumbnail)
									: null
								: undefined,
						},
					})
					await reloadCourse()
					return true
				} catch (error) {
					console.error(error)
					await alert.show("Error", "Failed to save course.")
				}

				return false
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [
		alert,
		autoApproveComments,
		chapterDripInterval,
		commentsEnabled,
		course,
		isLoaded,
		isThumbnailUpdated,
		issueCertificates,
		longDescription,
		membershipIds,
		platform.currency,
		platform.paymentGateway?.isEnabled,
		priceValue,
		reloadCourse,
		selectedTags,
		shortDescription,
		thumbnail,
		title,
		toast,
	])

	const initialLongDescription = useMemo(
		() => (course?.longDescription != null ? JSON.parse(course.longDescription) : null),
		[course?.longDescription],
	)

	if (course === null) return <Navigate to={routes.course.index()} />

	return (
		<Layout>
			<PageBreadcrumb title="Settings" path={[{ title: "Courses", link: routes.course.index() }]} />
			{courseError || tags.error ? (
				<p>Failed to load course.</p>
			) : !isLoaded || !course || !tags.data || longDescription === undefined ? (
				<PartialLoadingPage />
			) : (
				<ControlledTabs
					tabs={[
						{
							title: "Course information",
							content: (
								<CourseInformation
									title={title}
									shortDescription={shortDescription}
									commentsEnabled={commentsEnabled}
									autoApproveComments={autoApproveComments}
									selectedTags={selectedTags}
									thumbnail={thumbnail}
									setTitle={setTitle}
									setShortDescription={setShortDescription}
									setCommentsEnabled={setCommentsEnabled}
									setAutoApproveComments={setAutoApproveComments}
									setSelectedTags={setSelectedTags}
									setThumbnail={setThumbnail}
									setThumbnailUpdated={setThumbnailUpdated}
									tags={tags.data}
									reloadTags={tags.refetch}
								/>
							),
						},
						{
							title: "Issue certificates",
							content: <IssueCertificates value={issueCertificates} onChange={setIssueCertificates} />,
						},
						{
							title: "Chapter dripping",
							content: (
								<ChapterDripping interval={chapterDripInterval} onChange={setChapterDripInterval} />
							),
						},
						{
							title: "Long description",
							content: (
								<Suspense fallback={<PartialLoadingPage />}>
									<CourseLongDescriptionEditor
										initialValue={initialLongDescription}
										setValue={setLongDescription}
									/>
								</Suspense>
							),
						},
						{
							title: "Price & availability",
							content: (
								<>
									<Title>Course access</Title>
									<Description>Select how people access your course, and set a price.</Description>
									<ProductPricing
										type="course"
										price={price}
										setPrice={setPrice}
										membershipIds={membershipIds}
										setMembershipIds={setMembershipIds}
									/>
								</>
							),
						},
						{
							title: "Testers",
							content: <Testers courseId={course.id} />,
						},
					]}
				/>
			)}
		</Layout>
	)
}

export default CourseSettingsPage
