import { useEffect, useState } from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import FullLoadingPage from "~/components/FullLoadingPage"
import NotFoundPage from "~/pages/main/NotFoundPage"
import trpc, { query } from "~/utilities/trpc"

import ChapterEditor from "./ChapterEditor"
import Sidebar from "./Sidebar"

const CourseDetailPage: React.FC = () => {
	const courseId = parseNumber(useParams().courseId!)

	const { data: course, error, refetch } = query.course.get.useQuery(courseId ?? -1, { refetchOnWindowFocus: false })
	const [selectedChapterId, setSelectedChapterId] = useState<number | null>(null)
	const [editPageId, setEditPageId] = useState<number | null>(null)

	useEffect(() => {
		setSelectedChapterId(current => (current === null ? (course?.chapters[0]?.id ?? null) : current))
	}, [course])

	if (error) return <p>Failed to load course.</p>
	if (course === undefined) return <FullLoadingPage />
	if (course === null) return <NotFoundPage />

	return (
		<Container>
			<Sidebar
				course={{
					id: course.id,
					title: course.title,
					isPublished: course.publishDate !== null,
					chapters: course.chapters,
				}}
				reloadCourse={refetch}
				selectedChapterId={selectedChapterId}
				setSelectedChapterId={setSelectedChapterId}
				onCreateChapterClick={async () => {
					const chapter = await trpc.course.createChapter.mutate({ courseId: course.id })
					await refetch()
					setSelectedChapterId(chapter.id)
				}}
			/>
			{selectedChapterId === null ? (
				<ChooseChapterText>Create a chapter to begin editing the course</ChooseChapterText>
			) : (
				<ChapterEditor
					chapterId={selectedChapterId}
					editPageId={editPageId}
					setEditPageId={setEditPageId}
					reloadCourse={refetch}
				/>
			)}
		</Container>
	)
}

const Container = styled.div`
	min-height: 100vh;
	display: flex;
	background-color: white;
`

const ChooseChapterText = styled.p`
	width: 100%;
	text-align: center;
	align-self: center;
`

export default CourseDetailPage
