import { toPriceString } from "@forento/shared/utilities/currency"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { toPaymentStatusString } from "@forento/shared/utilities/payment"

import Button from "~/components/Button"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { query } from "~/utilities/trpc"

const BillingHistory: React.FC = () => {
	const { data: transactions, error } = query.payment.creator.listTransactions.useQuery()

	if (error) return <p>Failed to load billing history.</p>

	if (transactions === undefined) return <PartialLoadingPage />

	return (
		<Table
			columns={["Date", "Amount", "Status", "Receipt"]}
			data={transactions.map(transaction => [
				toDateTimeString(transaction.date),
				toPriceString(transaction),
				toPaymentStatusString(transaction.status),
				transaction.receipt.status === "available" ? (
					<Button onClick={transaction.receipt.url} newTab>
						Receipt
					</Button>
				) : (
					"No receipt"
				),
			])}
		/>
	)
}

export default BillingHistory
