import { type ReactNode, useState } from "react"
import snarkdown from "snarkdown"

import { addonTypes, type AddonType } from "@forento/shared/models/payment"
import { type AppSumoPlatformPlan, type VieDeDinguePlatformPlan } from "@forento/shared/models/platform"

import Button from "~/components/Button"
import { DefaultModal, ModalButtons, ModalTexts, ModalTitle } from "~/components/Modal"
import PaymentIntervalSelector from "~/components/PaymentIntervalSelector"
import { useUser } from "~/contexts/UserContext"
import CancelSubscriptionModal from "~/pages/settings/BillingSettingsPage/Plans/CancelSubscriptionModal"
import Addon from "~/pages/settings/BillingSettingsPage/Plans/useLtdAddon/Addon"
import { query } from "~/utilities/trpc"

import Plan, { PlanQuestion, PlanQuestions, PlanTitle } from "../Plan"

const questions: { question: string; answer: string }[] = [
	{
		question: "I already have a lifetime deal, do I need these upgrades?",
		answer: `
If you already have a lifetime deal, you have all the core features needed to build an online course empire. These addons are optional upgrades, offered exclusively to lifetime deal customers who want to get more value out of Forento.

The No commission addon is great for those who want to stop paying the sales commission fee, wants to use external payment gateways or just out for the Zapier integration.

The Scale addon includes all features of our Scale plan. It costs $150/month to get as a subscription, but offered to lifetime deal members at a discount of $100/month.
		`,
	},
	{
		question: "What's included in the No commission addon?",
		answer: `
With this addon, you can accept payments externally without relying on PayPal or Stripe. It provides functionality to assign and revoke product access to any of your academy members, either manually or automatically through Zapier.

Additionally, this addon eliminates all sales commission fees on your academy transactions (should you want to use Stripe or PayPal directly in Forento).

Whether you want to use a local payment gateway, sell via invoices, or scale your business, this addon is ideal for you.
- Assign and revoke product access for any academy member
- Zapier integration for connecting to external payment providers
- 0% sales commission fee for all sales, including PayPal and Stripe transactions made on your academy
		`,
	},
	{
		question: "What's included in the Scale addon?",
		answer: `
With this addon, you get all Forento has to offer. Scale is our most exclusive subscription tier, at an original price of $149/month. This addon is an exclusive offer limited to those who have a lifetime deal, at the great price of $50/month.

With this addon, you get access to:
- Everything from the No commission addon
- Bundles (For selling high-ticket bundles of products)
- Chapter Dripping (for releasing course content on a scheduele)
- Course Certificates (to automatically generate certificates upon course completion)
- And unlimited admin seats
		`,
	},
	{
		question: "How does the Zapier integration work?",
		answer: "Zapier can be used to easily create workflows and automations across different apps. For example, if a user joins a Forento membership, you may want to add them to an external email marketing tool as well. To integrate Forento with other applications, install our Zapier app, which can be found by going to the Settings menu → Zapier.",
	},
]

type PlatformPlan = AppSumoPlatformPlan | VieDeDinguePlatformPlan
type Result =
	| { status: "error" | "loading" }
	| { status: "success"; paymentIntervalComponent: ReactNode; planComponent: ReactNode }
export default function useLtdAddon({ platformPlan }: { platformPlan: PlatformPlan }): Result {
	const user = useUser()

	const [isMonthlySelected, setMonthlySelected] = useState(
		!platformPlan.addon || platformPlan.addon.paymentInterval === "month",
	)
	const [isCancelModalOpen, setCancelModalOpen] = useState(false)
	const [submittingAddon, setSubmittingAddon] = useState<AddonType>()
	const [faqModal, setFaqModal] = useState<{ isOpen: boolean; questionIndex: number }>({
		isOpen: false,
		questionIndex: 0,
	})

	const { data: addonDetails, error } = query.payment.creator.getAddonDetails.useQuery()

	if (error) return { status: "error" }
	if (!addonDetails) return { status: "loading" }
	return {
		status: "success",
		paymentIntervalComponent: (
			<PaymentIntervalSelector
				annualDiscount={
					1 -
					addonDetails.noCommission.prices.year.amount / 12 / addonDetails.noCommission.prices.month.amount
				}
				isMonthlySelected={isMonthlySelected}
				setMonthlySelected={setMonthlySelected}
			/>
		),
		planComponent: (
			<>
				<DefaultModal isOpen={faqModal.isOpen}>
					<ModalTitle>{questions[faqModal.questionIndex].question}</ModalTitle>
					<ModalTexts
						dangerouslySetInnerHTML={{
							__html: snarkdownWithParagraphs(questions[faqModal.questionIndex].answer.trim()),
						}}
					/>
					<ModalButtons>
						<Button
							variant="primary"
							onClick={() => setFaqModal(current => ({ ...current, isOpen: false }))}
						>
							Close
						</Button>
					</ModalButtons>
				</DefaultModal>
				<CancelSubscriptionModal
					type="addon"
					isOpen={isCancelModalOpen}
					onClose={() => setCancelModalOpen(false)}
					onSubmitted={async () => {
						await user.reload()
						setCancelModalOpen(false)
					}}
				/>
				{addonTypes.map(type => (
					<Addon
						key={type}
						type={type}
						details={addonDetails[type]}
						platformPlan={platformPlan}
						isMonthlySelected={isMonthlySelected}
						setSubmitting={value => setSubmittingAddon(value ? type : undefined)}
						isDisabled={submittingAddon !== undefined}
						isSubmitting={submittingAddon === type}
						onCancel={() => setCancelModalOpen(true)}
					/>
				))}
				<Plan>
					<PlanTitle>Why an addon?</PlanTitle>
					<PlanQuestions>
						{questions.map((item, index) => (
							<PlanQuestion
								key={index}
								onClick={() => setFaqModal({ isOpen: true, questionIndex: index })}
							>
								{item.question}
							</PlanQuestion>
						))}
					</PlanQuestions>
				</Plan>
			</>
		),
	}
}

function snarkdownWithParagraphs(markdown: string): string {
	const htmls = markdown
		.split(/(?:\r?\n){2,}/)
		.map(line =>
			[" ", "\t", "#", "-", "1", "*"].some(ch => line.startsWith(ch))
				? snarkdown(line)
				: `<p>${snarkdown(line)}</p>`,
		)
	return htmls.join("\n\n")
}
