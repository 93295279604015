import { type FC, useState } from "react"
import { useDropzone } from "react-dropzone"
import styled, { css } from "styled-components"

import { getDataUrlByFile } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import Button, { SubmitButton } from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import { DeleteIcon, UploadFileIcon } from "~/components/Icon"
import InputField, { useRichTextArea } from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import { dangerColor, primaryColor } from "~/utilities/styles"
import trpc, { query } from "~/utilities/trpc"

type Props = { channel: { id: number | null; name: string }; onPostCreated(): void }
const CreatePost: FC<Props> = ({ channel, onPostCreated }) => {
	const user = useUser()
	const alert = useAlert()

	const categories = query.community.listPostCategories.useQuery()

	const [isModalOpen, setModalOpen] = useState(false)
	const [title, setTitle] = useState("")
	const content = useRichTextArea({ label: "Text" })
	const [pollOptions, setPollOptions] = useState<string[]>([])
	const [categoryId, setCategoryId] = useState<string | null>(null)
	const [attachments, setAttachments] = useState<{ label: string; file: File }[]>([])
	const [isSubmitting, setSubmitting] = useState(false)

	const dropzone = useDropzone({
		onDrop: async files => {
			for (const file of files) {
				const dialog = await alert.input(
					"Add attachment",
					`Please enter a label for the file ${file.name}.`,
					"Label",
					file.name,
				)
				if (dialog.result === null) continue
				dialog.close()
				setAttachments(current => [...current, { label: dialog.result, file }])
			}
		},
	})

	const handleCreateCommunityProfile = async () => {
		if (user.user!.communityProfileId !== null) return

		await trpc.community.createProfile.mutate()
		await user.reload()
	}

	const handleCreatePost = async () => {
		const exportedContent = content.exportEditorState()
		if (title.trim().length === 0 || exportedContent === null) return

		setSubmitting(true)

		try {
			await trpc.community.createPost.mutate({
				channelId: channel.id,
				title: title.trim(),
				content: exportedContent,
				categoryId: parseNumber(categoryId),
				pollOptions,
				attachments: await Promise.all(
					attachments.map(async attachment => ({
						label: attachment.label,
						dataUrl: await getDataUrlByFile(attachment.file),
					})),
				),
			})

			setTitle("")
			content.clear()
			setCategoryId(null)
			setPollOptions([])
			setAttachments([])
			onPostCreated()
			setModalOpen(false)
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	if (user.user!.communityProfileId === null) {
		return (
			<Container>
				<p>
					In order to post and interact with others on the community, you need to create a community profile.
				</p>
				<Button variant="primary" onClick={handleCreateCommunityProfile}>
					Create community profile
				</Button>
			</Container>
		)
	}

	return (
		<Container>
			<Button variant="primary" onClick={() => setModalOpen(true)}>
				Create post
			</Button>
			<DefaultModal isOpen={isModalOpen} onSubmit={handleCreatePost}>
				<ModalTitle>Create a new post</ModalTitle>
				<ModalInputs>
					<InputField label="Title" value={title} onChange={setTitle} />
					{content.element}
					<PollOptions>
						{pollOptions.map((option, index) => (
							<PollOption key={index}>
								<PollOptionInput
									value={option}
									onChange={value =>
										setPollOptions(current => [
											...current.slice(0, index),
											value,
											...current.slice(index + 1),
										])
									}
								/>
								<PollOptionDeleteButton
									onClick={() =>
										setPollOptions(current => [
											...current.slice(0, index),
											...current.slice(index + 1),
										])
									}
								>
									<DeleteIcon />
								</PollOptionDeleteButton>
							</PollOption>
						))}
						<Button variant="secondary" onClick={() => setPollOptions(current => [...current, ""])}>
							Add poll option
						</Button>
					</PollOptions>
					<Dropdown
						items={[
							{ id: "none", title: "-- No category --" },
							...(categories.data?.map(category => ({
								id: category.id.toString(),
								title: category.label,
							})) ?? []),
							{ id: "create", title: "Create new category" },
						]}
						selectedItemId={categoryId ?? "none"}
						onChange={async id => {
							if (id === "create") {
								const result = await alert.input(
									"Create category",
									"Please enter a name for the category.",
									"Name",
								)
								if (result.result === null) return
								const category = await trpc.community.createPostCategory.mutate(result.result.trim())
								await categories.refetch()
								result.close()
								setCategoryId(category.id.toString())
								return
							}

							setCategoryId(id === "none" ? null : id)
						}}
					/>
					<Attachments>
						{attachments.map((attachment, index) => (
							<Attachment key={index}>
								<DeleteAttachment
									onClick={() =>
										setAttachments([
											...attachments.slice(0, index),
											...attachments.slice(index + 1),
										])
									}
								>
									<DeleteIcon />
								</DeleteAttachment>
								{attachment.label}
							</Attachment>
						))}
						<AddAttachment {...dropzone.getRootProps()}>
							<input {...dropzone.getInputProps()} />
							<AddAttachmentIcon />
						</AddAttachment>
					</Attachments>
				</ModalInputs>
				<ModalButtons>
					<Button variant="secondary" isDisabled={isSubmitting} onClick={() => setModalOpen(false)}>
						Close
					</Button>
					<StyledSubmitButton variant="primary" isLoading={isSubmitting}>
						Post to {channel.name}
					</StyledSubmitButton>
				</ModalButtons>
			</DefaultModal>
		</Container>
	)
}

const Container = styled.div`
	grid-area: create-post;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
`

const PollOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PollOption = styled.div`
	display: flex;
	gap: 16px;
`

const PollOptionInput = styled(InputField)`
	flex: 1;
`

const PollOptionDeleteButton = styled(Button)`
	width: 52px;
	height: 52px;
	background-color: ${dangerColor};
	color: white;
	padding: 8px;
	border-radius: 4px;
`

const Attachments = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
`

const attachmentStyles = css`
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Attachment = styled.div`
	${attachmentStyles}
	word-break: break-all;
	position: relative;
`

const DeleteAttachment = styled(Button)`
	position: absolute;
	top: 4px;
	right: 4px;
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

const AddAttachment = styled.div`
	${attachmentStyles};
	cursor: pointer;
`

const AddAttachmentIcon = styled(UploadFileIcon)`
	width: 24px;
	height: 24px;
	color: ${primaryColor};
`

const StyledSubmitButton = styled(SubmitButton)`
	align-self: flex-start;
`

export default CreatePost
