import { type FC, useId, useState } from "react"

import { type CommunityAccess, communityAccess } from "@forento/shared/models/community"
import { getCommunityAccessDisplayName, isCommunityAccessHigher } from "@forento/shared/utilities/community"

import Button, { SubmitButton } from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import InputField from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

type Props = { isOpen: boolean; onClose(): void; onCreated(): Promise<unknown> }
const CreateModal: FC<Props> = ({ isOpen, onClose, onCreated }) => {
	const platform = usePlatform()!
	const alert = useAlert()

	const accessId = useId()
	const membershipAccessId = useId()

	const [name, setName] = useState("")
	const [defaultAccess, setDefaultAccess] = useState<CommunityAccess | null>("post")
	const [membershipAccess, setMembershipAccess] = useState<Record<number, CommunityAccess>>({})
	const [isSubmitting, setSubmitting] = useState(false)

	const memberships = query.membership.list.useQuery()

	async function handleCreate() {
		setSubmitting(true)
		try {
			await trpc.community.createChannel.mutate({ name, defaultAccess, membershipAccess })
			await onCreated()
			setName("")
			setDefaultAccess("post")
			setMembershipAccess({})
			onClose()
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to create the channel. Please try again.")
		} finally {
			setSubmitting(false)
		}
	}

	if (!platform.plan.access.communityChannels) {
		return (
			<DefaultModal isOpen={isOpen}>
				<ModalTitle>Create a new channel</ModalTitle>
				<ModalText>You need to upgrade your plan to create channels.</ModalText>
				<ModalButtons>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button variant="primary" onClick={routes.settings.billing.plans()}>
						Visit plans
					</Button>
				</ModalButtons>
			</DefaultModal>
		)
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleCreate}>
			<ModalTitle>Create a new channel</ModalTitle>
			{memberships.error ? (
				<ModalText>Failed to load data.</ModalText>
			) : memberships.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					<ModalText>
						Channels are used to organize posts. You can set the default access level for the channel.
					</ModalText>
					<ModalInputs>
						<InputField label="Channel name" value={name} onChange={setName} />
						<div>
							<InputLabel htmlFor={accessId}>Default user access</InputLabel>
							<Dropdown
								id={accessId}
								items={[
									{ id: "none", title: "None" },
									...communityAccess.map(access => ({
										id: access,
										title: getCommunityAccessDisplayName(access),
									})),
								]}
								selectedItemId={defaultAccess ?? "none"}
								onChange={value => setDefaultAccess(communityAccess.find(x => x === value) ?? null)}
							/>
						</div>
						{memberships.data.map(membership => (
							<div key={membership.id}>
								<InputLabel htmlFor={`${membershipAccessId}-${membership.id}`}>
									Access for {membership.title}
								</InputLabel>
								<Dropdown
									id={`${membershipAccessId}-${membership.id}`}
									items={[
										{ id: "default", title: "-- Same as default --" },
										...communityAccess
											.filter(access => isCommunityAccessHigher(access, defaultAccess))
											.map(access => ({
												id: access,
												title: getCommunityAccessDisplayName(access),
											})),
									]}
									selectedItemId={membershipAccess[membership.id] ?? "default"}
									onChange={value => {
										const access = communityAccess.find(x => x === value)
										if (access) {
											setMembershipAccess(current => ({ ...current, [membership.id]: access }))
										} else {
											setMembershipAccess(current => {
												delete current[membership.id]
												return { ...current }
											})
										}
									}}
								/>
							</div>
						))}
					</ModalInputs>
					<ModalButtons>
						<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
							Cancel
						</Button>
						<SubmitButton variant="primary" isLoading={isSubmitting}>
							Create
						</SubmitButton>
					</ModalButtons>
				</>
			)}
		</DefaultModal>
	)
}

export default CreateModal
