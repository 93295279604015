import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { useLayoutEffect, useState, type FC } from "react"

import Button from "~/components/Button"
import DetailedList from "~/components/DetailedList"
import Layout, { PageHeader, PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import SortableListContext from "~/contexts/SortableListContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import Video from "./Video"

const VideosPage: FC = () => {
	const { data: videos, error } = query.video.list.useQuery()

	const [sortOrder, setSortOrder] = useState<number[]>([])

	useLayoutEffect(() => {
		setSortOrder(videos?.map(video => video.id) ?? [])
	}, [videos])

	function handleDragEnd(event: DragEndEvent) {
		if (!videos) return
		const { active, over } = event
		if (!over || active.id === over.id) return

		const newItems = arrayMove(sortOrder, sortOrder.indexOf(Number(active.id)), sortOrder.indexOf(Number(over.id)))
		setSortOrder(newItems)

		for (const video of videos) {
			trpc.video.update.mutate({ videoId: video.id, data: { sortOrder: newItems.indexOf(video.id) } })
		}
	}

	return (
		<Layout>
			<PageHeader>
				<PageTitle>Videos</PageTitle>
				<Button variant="primary" onClick={routes.video.create()}>
					Upload video
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load videos.</p>
			) : videos === undefined ? (
				<PartialLoadingPage />
			) : (
				<SortableListContext items={sortOrder} onDragEnd={handleDragEnd}>
					<DetailedList>
						{[...videos]
							.sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
							.map(video => (
								<Video key={video.id} video={video} />
							))}
					</DetailedList>
				</SortableListContext>
			)}
		</Layout>
	)
}

export default withAccessRequirement("videos", <PageTitle>Videos</PageTitle>, VideosPage)
