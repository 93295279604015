import { useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router"

import Form from "@forento/shared/components/Form"
import { getCookieByName } from "@forento/shared/utilities/cookie"

import { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useDisableGoogleSignIn } from "~/contexts/GoogleSignInContext"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

import { signup } from "."
import { Content, ErrorText, Inputs, InputsRow, NavigationButton, NavigationText, Text, Title } from "../Layout"

const PlatformInvite: React.FC<{ token: string }> = ({ token }) => {
	const user = useUser()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	useDisableGoogleSignIn()

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")

	const [error, setError] = useState<string>()
	const [isSubmitting, setSubmitting] = useState(false)

	const invite = query.user.verifyCreatorInviteToken.useQuery(token)

	const handleSignup = async () => {
		if (token === null) return

		setSubmitting(true)
		const result = await signup({
			firstName,
			lastName,
			password,
			passwordConfirmation,
			inviteToken: token,
			source: searchParams.get("s") ?? getCookieByName("s") ?? undefined,
		})
		setSubmitting(false)
		if (result.status !== "success") {
			setError(result.error)
			return
		}

		await user.reload()

		navigate(routes.main.index())
	}

	if (invite.error) {
		return <Text>Failed to load invite. Please try again later.</Text>
	}
	if (invite.data === undefined) {
		return <PartialLoadingPage />
	}
	if (invite.data.status === "invalid") {
		return (
			<Text>Your academy invite is unfortunately invalid. Make sure you have entered the full URL address.</Text>
		)
	} else if (invite.data.status === "expired") {
		return (
			<Text>
				Your academy invite has unfortunately expired. You need to ask the academy admin to invite you again.
			</Text>
		)
	}

	return (
		<Content as={Form} onSubmit={handleSignup}>
			<Title>Register a new account</Title>
			{error !== undefined && <ErrorText>{error}</ErrorText>}
			<Text>
				You've been invited to be part of <strong>{invite.data.invite.platform.name}</strong>.
			</Text>
			<Inputs>
				<InputsRow>
					<InputField
						label="First name"
						value={firstName}
						onChange={setFirstName}
						autoComplete="given-name"
					/>

					<InputField label="Last name" value={lastName} onChange={setLastName} autoComplete="family-name" />
				</InputsRow>

				<InputField
					inputType="email"
					label="Email"
					value={invite.data.invite.email}
					autoComplete="email"
					disabled
				/>

				<InputField
					inputType="password"
					label="Password"
					value={password}
					onChange={setPassword}
					autoComplete="new-password"
				/>

				<InputField
					inputType="password"
					label="Confirm password"
					value={passwordConfirmation}
					onChange={setPasswordConfirmation}
					autoComplete="new-password"
				/>
			</Inputs>

			<NavigationText>
				By signing up you agree to our{" "}
				<NavigationButton onClick="https://forento.io/policy/terms-of-service" newTab>
					Terms of Service
				</NavigationButton>
				.
			</NavigationText>

			<SubmitButton variant="primary" isLoading={isSubmitting}>
				Sign up
			</SubmitButton>
			<NavigationText>
				Already have an account? <NavigationButton onClick={routes.account.signin()}>Sign in</NavigationButton>
			</NavigationText>
		</Content>
	)
}

export default PlatformInvite
