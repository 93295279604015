import dayjs from "dayjs"
import { type FC, useState } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { toPercentString, toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/components/Button"
import { DeleteIcon } from "~/components/Icon"
import Layout, { PageHeader, PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useUser } from "~/contexts/UserContext"
import withGatewayRequirement from "~/hocs/withGatewayRequirement"
import { dangerColor } from "~/utilities/styles"
import trpc, { query } from "~/utilities/trpc"

import CreateCodeModal from "./CreateCodeModal"

const DiscountCodesPage: FC = () => {
	const platform = useUser().user!.platform!

	const { data: discountCodes, error, refetch } = query.payment.stripe.listDiscountCodes.useQuery()

	const [isCreateCodeModalOpen, setCreateCodeModalOpen] = useState(false)
	const [deletingIds, setDeletingIds] = useState<string[]>([])

	const handleDeleteCodeById = async (codeId: string) => {
		setDeletingIds([...deletingIds, codeId])
		try {
			await trpc.payment.stripe.deleteDiscountCode.mutate(codeId)
			await refetch()
		} catch (error) {
			console.error(error)
		} finally {
			setDeletingIds(deletingIds.filter(id => id !== codeId.toString()))
		}
	}

	return (
		<Layout>
			<CreateCodeModal
				isOpen={isCreateCodeModalOpen}
				onSubmit={async () => {
					setCreateCodeModalOpen(false)
					await refetch()
				}}
				onClose={() => setCreateCodeModalOpen(false)}
			/>
			<PageHeader>
				<PageTitle>Discount Codes</PageTitle>
				<Button variant="primary" onClick={() => setCreateCodeModalOpen(true)}>
					Create new
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load discount codes.</p>
			) : discountCodes === undefined ? (
				<PartialLoadingPage />
			) : (
				<Table
					columns={["Name", "Code", "Discount", "Duration", "Redemptions", "Expires", "Manage"]}
					data={discountCodes.map(code => [
						code.name,
						code.code,
						code.discount.type === "amount"
							? toPriceString({ amount: code.discount.value, currency: platform.currency })
							: toPercentString(code.discount.value),
						code.duration.type === "once"
							? "Once"
							: code.duration.type === "forever"
								? "Forever"
								: code.duration.type === "repeating"
									? `${code.duration.months} months`
									: null,
						code.maxRedemptions ?? "Unlimited",
						code.lastRedepmptionDate !== null
							? dayjs(code.lastRedepmptionDate).format("YYYY-MM-DD HH:mm")
							: "Never",
						<Actions>
							<Tooltip tooltip="Delete">
								<DeleteButton
									onClick={() => handleDeleteCodeById(code.id)}
									isLoading={deletingIds.includes(code.id)}
									replaceOnLoading
								>
									<DeleteIcon />
								</DeleteButton>
							</Tooltip>
						</Actions>,
					])}
				/>
			)}
		</Layout>
	)
}

const Actions = styled.div`
	display: flex;
`

const DeleteButton = styled(Button)`
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

export default withGatewayRequirement(
	"stripe",
	<PageHeader>
		<PageTitle>Discount Codes</PageTitle>
	</PageHeader>,
	DiscountCodesPage,
)
