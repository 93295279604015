import { useCallback, type FC } from "react"
import styled from "styled-components"

import { type IntendedPageType, type CoursePageInputType } from "@forento/shared/models/course"
import { type VideoCaptionLanguageCode } from "@forento/shared/models/video"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import { DefaultModal } from "~/components/Modal"
import trpc from "~/utilities/trpc"

import EditCustomPageModalContent from "./EditCustomPageModalContent"
import EditInputPageModalContent from "./EditInputPageModalContent"
import EditQuizPageModalContent from "./EditQuizPageModalContent"
import EditTextPageModalContent from "./EditTextPageModalContent"
import EditVideoPageModalContent from "./EditVideoPageModalContent"
import EditVimeoPageModalContent from "./EditVimeoPageModalContent"
import EditYouTubePageModalContent from "./EditYouTubePageModalContent"

type Props = {
	isOpen: boolean
	type: IntendedPageType
	page: {
		id: number
		title: string
		text: string | null
		image: { filePath: string; contain: boolean } | null
		quiz: { answers: { text: string; isCorrect: boolean }[] | null } | null
		video:
			| { host: "apiVideo"; apiVideoId: string }
			| { host: "bunny"; bunnyVideoId: string; thumbnailUrl: string | null; captions: VideoCaptionLanguageCode[] }
			| null
		youTubeVideoId: string | null
		vimeoVideoId: string | null
		inputType: CoursePageInputType | null
		attachments: {
			id: number
			fileType: string
			label: string
			url: string
		}[]
		links: {
			id: number
			label: string
			url: string
		}[]
	}
	onClose: (args?: { reloadCourse?: boolean }) => void
}
const EditPageModal: FC<Props> = ({ isOpen, type, page, onClose }) => {
	const handleSavePage = useCallback(
		async (data: {
			title: string
			text: string | null
			youTubeVideoId?: string
			vimeoVideoId?: string
			thumbnailDataUrl?: string | null
			containImage?: boolean
			removeCaptions?: VideoCaptionLanguageCode[]
			addCaptions?: { language: VideoCaptionLanguageCode; base64: string }[]
		}) => {
			await trpc.course.updatePage.mutate({ id: page.id, data })
			onClose({ reloadCourse: true })
		},
		[page.id, onClose],
	)

	const handleDeletePage = useCallback(async () => {
		await trpc.course.deletePage.mutate(page.id)
		onClose({ reloadCourse: true })
	}, [page.id, onClose])

	return (
		<DefaultModal isOpen={isOpen} width={600}>
			<Header>Edit page</Header>
			<Content>
				{type === "video" ? (
					<EditVideoPageModalContent
						key={page.id}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
						page={page}
					/>
				) : type === "youtube" ? (
					<EditYouTubePageModalContent
						key={page.id}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
						page={page}
					/>
				) : type === "vimeo" ? (
					<EditVimeoPageModalContent
						key={page.id}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
						page={page}
					/>
				) : type === "text" ? (
					<EditTextPageModalContent
						key={page.id}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
						page={page}
					/>
				) : type === "input" ? (
					<EditInputPageModalContent
						key={page.id}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
						page={page}
					/>
				) : type === "quiz" ? (
					<EditQuizPageModalContent
						key={page.id}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
						page={page}
					/>
				) : type === "custom" ? (
					<EditCustomPageModalContent
						key={page.id}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
						page={page}
					/>
				) : (
					exhaustiveGuard(type)
				)}
			</Content>
		</DefaultModal>
	)
}

const Header = styled.div`
	font-weight: 600;
	font-size: 18px;
	color: #101828;
	margin: 0 24px;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 24px 24px 0;
`

export default EditPageModal
