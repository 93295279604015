import dayjs from "dayjs"
import { type FC } from "react"
import styled from "styled-components"

import { type RecentEvent } from "@forento/shared/models/dashboard"
import { toTinyRelativeDateString } from "@forento/shared/utilities/date"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import Button from "~/components/Button"
import routes from "~/utilities/routes"
import { primaryLightColor } from "~/utilities/styles"

const RecentEvents: FC<{ events: RecentEvent[] }> = ({ events }) => (
	<Container>
		<Title>Recent events</Title>
		<Content>
			{events.map((event, index) => (
				<EventContainer key={index}>
					<EventTitle>
						<Event event={event} />
					</EventTitle>
					<EventDate>{toTinyRelativeDateString(dayjs().diff(event.date, "seconds"))}</EventDate>
				</EventContainer>
			))}
		</Content>
	</Container>
)

const Event: FC<{ event: RecentEvent }> = ({ event }) => {
	if (event.type === "community-post") {
		return (
			<>
				<EventLink onClick={routes.community.profile(event.profile.id)}>{event.profile.name}</EventLink> created
				a community post!
			</>
		)
	}

	const userLink = <EventLink onClick={routes.user.detail(event.user.id)}>{event.user.name}</EventLink>
	switch (event.type) {
		case "user":
			return <>{userLink} signed up!</>
		case "order":
			return (
				<>
					{userLink} purchased {event.productName}!
				</>
			)
		case "subscription":
			return (
				<>
					{userLink} subscribed to {event.productName}!
				</>
			)
		case "certificate":
		case "community-profile":
			return <>{userLink} created a community profile!</>
		default:
			exhaustiveGuard(event)
	}
}

const Container = styled.div`
	flex: 0.5;
	background-color: ${primaryLightColor};
	padding: 34px 30px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 32px;
	margin-bottom: 20px;
`

const Content = styled.div`
	background-color: white;
	border-radius: 12px;
	padding: 12px 20px;
`

const EventContainer = styled.div`
	padding: 12px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;

	&:not(:last-child) {
		padding-bottom: 11px;
		border-bottom: 1px solid #d2d2d2;
	}
`

const EventTitle = styled.div`
	font-weight: 600;
	font-size: 16px;
`

const EventLink = styled(Button).attrs({ variant: "link" })`
	display: inline;
`

const EventDate = styled.div`
	font-size: 14px;
	color: #666;
`

export default RecentEvents
