import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { useLayoutEffect, useState, type FC } from "react"
import { useNavigate } from "react-router"
import styled from "styled-components"

import { type Bundle as BundleType } from "@forento/shared/models/bundle"

import Button, { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import Layout, { PageHeader, PageTitle } from "~/components/Layout"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import SortableListContext from "~/contexts/SortableListContext"
import { usePlatform } from "~/contexts/UserContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import usePrice from "~/hooks/usePrice"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import Bundle from "./Bundle"

const BundlesPage: FC = () => {
	const platform = usePlatform()!
	const alert = useAlert()
	const navigate = useNavigate()

	const [isCreateOpen, setCreateOpen] = useState(false)
	const [name, setName] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const { price, priceValue, setPrice } = usePrice()
	const [isSubmitting, setSubmitting] = useState(false)

	const { data, error, refetch } = query.bundle.list.useQuery()

	async function handleCreate() {
		if (name.trim().length === 0 || priceValue === null) {
			await alert.show("Error", "Please fill in all required fields.")
			return
		}

		setSubmitting(true)
		try {
			const bundle = await trpc.bundle.create.mutate({ name, shortDescription, price: priceValue })
			await refetch()
			setName("")
			setShortDescription("")
			setPrice("")
			setCreateOpen(false)
			navigate(routes.bundle.detail(bundle.id))
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to create bundle.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<DefaultModal isOpen={isCreateOpen} onSubmit={handleCreate}>
				<ModalTitle>Create a new bundle</ModalTitle>
				<ModalInputs>
					<InputField label="Name *" value={name} onChange={setName} />
					<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
					<InputField label={`Price (${platform.currency}) *`} value={price} onChange={setPrice} />
				</ModalInputs>
				<ModalButtons>
					<Button variant="secondary" onClick={() => setCreateOpen(false)} isDisabled={isSubmitting}>
						Cancel
					</Button>
					<SubmitButton variant="primary" isLoading={isSubmitting}>
						Create bundle
					</SubmitButton>
				</ModalButtons>
			</DefaultModal>
			<PageHeader>
				<PageTitle>Bundles</PageTitle>
				<Button variant="primary" onClick={() => setCreateOpen(true)}>
					Create new
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load bundles.</p>
			) : !data ? (
				<PartialLoadingPage />
			) : (
				<Content bundles={data} reload={refetch} />
			)}
		</Layout>
	)
}

const Content: FC<{ bundles: BundleType[]; reload(): Promise<unknown> }> = ({ bundles, reload }) => {
	const [sortOrder, setSortOrder] = useState<number[]>([])

	useLayoutEffect(() => {
		setSortOrder(bundles.map(bundle => bundle.id))
	}, [bundles])

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event
		if (!over || active.id === over.id) return

		const newItems = arrayMove(sortOrder, sortOrder.indexOf(Number(active.id)), sortOrder.indexOf(Number(over.id)))
		setSortOrder(newItems)

		for (const bundle of bundles) {
			trpc.bundle.update.mutate({
				id: bundle.id,
				data: { sortOrder: newItems.indexOf(bundle.id) },
			})
		}
	}

	return (
		<SortableListContext items={sortOrder} onDragEnd={handleDragEnd}>
			<Bundles>
				{[...bundles]
					.sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
					.map(bundle => (
						<Bundle key={bundle.id} bundle={bundle} onDelete={reload} />
					))}
			</Bundles>
		</SortableListContext>
	)
}

const Bundles = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default withAccessRequirement(
	"bundles",
	<PageHeader>
		<PageTitle>Bundles</PageTitle>
	</PageHeader>,
	BundlesPage,
)
