import { DefaultModal, ModalButtons, ModalContentPadding, ModalTitle } from "~/components/Modal"
import { query } from "~/utilities/trpc"

import Button from "../Button"
import PartialLoadingPage from "../PartialLoadingPage"
import Reaction from "./Reaction"

const PostReactions: React.FC<{ postId: number | null; onClose(): void }> = ({ postId, onClose }) => {
	const { data: reactions, error } = query.community.listPostReactions.useQuery(postId!, { enabled: postId !== null })

	if (postId === null) return null

	return (
		<DefaultModal isOpen={postId !== null}>
			<ModalTitle>Likes</ModalTitle>
			<ModalContentPadding>
				{reactions === undefined ? (
					<PartialLoadingPage />
				) : error ? (
					<p>Failed to load likes.</p>
				) : (
					reactions.map(reaction => <Reaction key={reaction.profile.id} reaction={reaction} />)
				)}
			</ModalContentPadding>
			<ModalButtons>
				<Button variant="primary" onClick={onClose}>
					Close
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

export default PostReactions
