import { useState, type FC, useLayoutEffect, type Dispatch, type SetStateAction } from "react"
import styled from "styled-components"

import { toggleStateArray } from "@forento/shared/utilities/array"

import Checkbox from "~/components/Checkbox"
import InputField from "~/components/InputField"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import RadioButtons from "~/components/RadioButtons"
import { usePlatform } from "~/contexts/UserContext"
import { query } from "~/utilities/trpc"

type OptionId = "free" | "sign-in" | "paid" | "membership" | "paid-and-membership"
type Props = {
	type: "course" | "video" | "downloadable" | "session"
	isSignInRequired?: boolean
	setSignInRequired?: Dispatch<SetStateAction<boolean>>
	price: string
	setPrice: Dispatch<string>
	membershipIds: number[]
	setMembershipIds: Dispatch<SetStateAction<number[]>>
}
const ProductPricing: FC<Props> = ({
	type,
	isSignInRequired,
	setSignInRequired,
	price,
	setPrice,
	membershipIds,
	setMembershipIds,
}) => {
	const platform = usePlatform()!

	const [selectedOptionId, setSelectedOptionId] = useState<OptionId>(
		membershipIds.length > 0 && price.length > 0
			? "paid-and-membership"
			: price.length > 0
				? "paid"
				: membershipIds.length > 0
					? "membership"
					: isSignInRequired
						? "sign-in"
						: "free",
	)

	const memberships = query.membership.list.useQuery()

	useLayoutEffect(() => {
		if (selectedOptionId === "free") {
			setSignInRequired?.(false)
			setPrice("")
			setMembershipIds([])
		} else if (selectedOptionId === "sign-in") {
			setSignInRequired?.(true)
			setPrice("")
			setMembershipIds([])
		} else if (selectedOptionId === "paid") {
			setSignInRequired?.(false)
		} else if (selectedOptionId === "membership") {
			setSignInRequired?.(false)
			setPrice("")
		} else if (selectedOptionId === "paid-and-membership") {
			setSignInRequired?.(false)
		}
	}, [selectedOptionId, setMembershipIds, setPrice, setSignInRequired])

	const options: { id: OptionId; label: string; description: string }[] = [
		{ id: "free", label: "Free", description: `Your ${type} will be available to everyone for free.` },
		...(setSignInRequired !== undefined
			? [
					{
						id: "sign-in" as const,
						label: "Signed in",
						description: `Your ${type} will be available to signed in users.`,
					},
				]
			: []),
		{
			id: "paid",
			label: "Paid",
			description: `Your ${type} will be available to paid users (or if manually added by you).`,
		},
		{
			id: "membership",
			label: "Membership",
			description: `Your ${type} will be available to members of a specific membership.`,
		},
		{
			id: "paid-and-membership",
			label: "Paid and membership",
			description: `Your ${type} will be available to paid users and members of a specific membership.`,
		},
	]

	return (
		<>
			<RadioButtons
				value={selectedOptionId}
				onChange={value => setSelectedOptionId(options.find(option => option.id === value)?.id ?? "free")}
				options={options.map(option => ({
					value: option.id,
					label: option.label,
					description: option.description,
				}))}
			/>

			{(selectedOptionId === "paid" || selectedOptionId === "paid-and-membership") && (
				<Content>
					<InputField label={`Price (${platform.currency})`} value={price} onChange={setPrice} />
				</Content>
			)}
			{(selectedOptionId === "membership" || selectedOptionId === "paid-and-membership") &&
				(memberships.error ? (
					<Content>Failed to load memberships.</Content>
				) : !memberships.data ? (
					<Content>
						<PartialLoadingPage />
					</Content>
				) : (
					<Content>
						{memberships.data.map(membership => (
							<Checkbox
								key={membership.id}
								isChecked={membershipIds.includes(membership.id)}
								label={membership.title}
								onChange={() => toggleStateArray(setMembershipIds, membership.id)}
							/>
						))}
					</Content>
				))}
		</>
	)
}

const Content = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default ProductPricing
