import { useState } from "react"
import styled from "styled-components"

import Button, { SubmitButton } from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc, { query } from "~/utilities/trpc"

const CurrencyModal: React.FC<{ isOpen: boolean; onClose(): void }> = ({ isOpen, onClose }) => {
	const user = useUser()
	const alert = useAlert()

	const { data: currencies, error } = query.payment.listCurrencies.useQuery()

	const [selectedCurrency, setSelectedCurrency] = useState<string>(user.user!.platform!.currency)
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		setSubmitting(true)
		try {
			const { status } = await trpc.platform.setCurrency.mutate(selectedCurrency)
			if (status === "has-active-subscriptions") {
				alert.show("Active subscriptions", "You can't update the currency when you have active subscriptions.")
				return
			}
			onClose()
			await user.reload()
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Change currency</ModalTitle>
			<Content>
				{error ? (
					<ModalText>Failed to load currencies. Please try again later.</ModalText>
				) : currencies === undefined ? (
					<ModalContentPadding>
						<PartialLoadingPage />
					</ModalContentPadding>
				) : (
					<ModalContentPadding>
						<Dropdown
							items={currencies.map(currency => ({ id: currency, title: currency }))}
							selectedItemId={selectedCurrency}
							onChange={setSelectedCurrency}
						/>
					</ModalContentPadding>
				)}
				<ModalText>
					When updating the currency your current products' prices will not change in price but will in
					currency, so make sure to update the price to reflect the desired price in the new currency. This
					will also clear any sales statistics in other currencies from the analytics tab.
				</ModalText>
			</Content>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Change currency
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default CurrencyModal
