import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { useLayoutEffect, useState, type FC } from "react"
import styled from "styled-components"

import { type Downloadable as DownloadableType } from "@forento/shared/models/downloadable"

import Button from "~/components/Button"
import Layout, { PageHeader, PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import SortableListContext from "~/contexts/SortableListContext"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import Downloadable from "./Downloadable"

const DownloadablesPage: FC = () => {
	const { data: downloadables, error, refetch } = query.downloadable.list.useQuery()

	return (
		<Layout>
			<PageHeader>
				<PageTitle>Downloadables</PageTitle>
				<Button variant="primary" onClick={routes.downloadable.create()}>
					Create new
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load downloadables.</p>
			) : downloadables === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content downloadables={downloadables} reload={refetch} />
			)}
		</Layout>
	)
}

type ContentProps = { downloadables: DownloadableType[]; reload(): void }
const Content: FC<ContentProps> = ({ downloadables, reload }) => {
	const [sortOrder, setSortOrder] = useState<number[]>([])

	useLayoutEffect(() => {
		setSortOrder(downloadables.map(downloadable => downloadable.id))
	}, [downloadables])

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event
		if (!over || active.id === over.id) return

		const newItems = arrayMove(sortOrder, sortOrder.indexOf(Number(active.id)), sortOrder.indexOf(Number(over.id)))
		setSortOrder(newItems)

		for (const downloadable of downloadables) {
			trpc.downloadable.update.mutate({
				id: downloadable.id,
				data: { sortOrder: newItems.indexOf(downloadable.id) },
			})
		}
	}

	return (
		<SortableListContext items={sortOrder} onDragEnd={handleDragEnd}>
			<Downloadables>
				{[...downloadables]
					.sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
					.map(downloadable => (
						<Downloadable key={downloadable.id} downloadable={downloadable} onDelete={reload} />
					))}
			</Downloadables>
		</SortableListContext>
	)
}

const Downloadables = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default DownloadablesPage
