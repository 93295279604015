import { type FC, useState, useEffect } from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import { type Downloadable } from "@forento/shared/models/downloadable"
import { getDataUrlByFile, getFileNameFromPath } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import FileChooser from "~/components/FileChooser"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import usePrice from "~/hooks/usePrice"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

const EditDownloadablePage: FC = () => {
	const downloadableId = parseNumber(useParams().downloadableId) ?? -1

	const [thumbnail, setThumbnail] = useState<File | null>()

	const downloadable = query.downloadable.get.useQuery(downloadableId)

	useEffect(() => {
		if (!downloadable.data) return

		if (downloadable.data.thumbnailFilePath === null) {
			setThumbnail(null)
			return
		}

		const abortController = new AbortController()
		fetch(downloadable.data.thumbnailFilePath, { signal: abortController.signal })
			.then(async x => ({
				name: getFileNameFromPath(x.url),
				blob: await x.blob(),
			}))
			.then(({ name, blob }) => {
				if (abortController.signal.aborted) return
				setThumbnail(new File([blob], name ?? "Thumbnail", { type: blob.type }))
			})

		return () => {
			abortController.abort()
		}
	}, [downloadable.data])

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb
					path={[{ title: "Downloadables", link: routes.downloadable.index() }]}
					title={downloadable.data?.title ?? "..."}
				/>
			</PageHeader>
			{downloadable.error ? (
				<p>Failed to fetch downloadable.</p>
			) : !downloadable.data || thumbnail === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content downloadable={{ ...downloadable.data, thumbnail }} reload={downloadable.refetch} />
			)}
		</Layout>
	)
}

type ContentProps = { downloadable: Downloadable & { thumbnail: File | null }; reload(): Promise<unknown> }
const Content: FC<ContentProps> = ({ downloadable, reload }) => {
	const toast = useToast()
	const alert = useAlert()

	const [name, setName] = useState(downloadable.title)
	const [shortDescription, setShortDescription] = useState(downloadable.shortDescription ?? "")
	const longDescription = useRichTextArea({ label: "Description", initialValue: downloadable.longDescription })
	const [isSignInRequired, setSignInRequired] = useState(downloadable.isSignInRequired)
	const { price, priceValue, setPrice } = usePrice(downloadable.price?.amount)
	const [membershipIds, setMembershipIds] = useState<number[]>(downloadable.membershipIds)
	const [thumbnail, setThumbnail] = useState<File | null>(downloadable.thumbnail)
	const [isThumbnailModified, setThumbnailModified] = useState(false)

	useEffect(() => {
		setName(downloadable.title)
		setShortDescription(downloadable.shortDescription ?? "")
		setSignInRequired(downloadable.isSignInRequired ?? false)
		setPrice(downloadable.price?.amount.toString() ?? "")
		setMembershipIds(downloadable.membershipIds)
		setThumbnail(downloadable.thumbnail)
	}, [downloadable, setPrice])

	useEffect(() => {
		const isModified =
			name !== downloadable.title ||
			shortDescription !== (downloadable.shortDescription ?? "") ||
			longDescription.isModified ||
			isSignInRequired !== downloadable.isSignInRequired ||
			priceValue !== (downloadable.price?.amount ?? null) ||
			membershipIds.join(",") !== downloadable.membershipIds.join(",") ||
			thumbnail !== downloadable.thumbnail ||
			isThumbnailModified

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				if (name.trim().length === 0) return false

				try {
					await trpc.downloadable.update.mutate({
						id: downloadable.id,
						data: {
							name,
							shortDescription,
							longDescription: longDescription.exportEditorState(),
							isSignInRequired,
							price: priceValue,
							membershipIds,
							thumbnailDataUrl: thumbnail ? await getDataUrlByFile(thumbnail) : null,
						},
					})
					await reload()
					setThumbnailModified(false)
					return true
				} catch (error) {
					console.error(error)
					await alert.show("Error", "Failed to save downloadable. Please try again.")
					return false
				}
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [
		alert,
		downloadable,
		isSignInRequired,
		isThumbnailModified,
		longDescription,
		membershipIds,
		name,
		priceValue,
		reload,
		shortDescription,
		thumbnail,
		toast,
	])

	return (
		<Container>
			<Section>
				<InputField label="Name" value={name} onChange={setName} />
				<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
				{longDescription.element}
				<div>
					<InputLabel>Thumbnail (optional)</InputLabel>
					<FileChooser
						type="file"
						value={thumbnail}
						onChange={value => {
							setThumbnail(value)
							setThumbnailModified(true)
						}}
					/>
				</div>
			</Section>
			<Section>
				<Title>Pricing</Title>
				<ProductPricing
					type="downloadable"
					isSignInRequired={isSignInRequired}
					setSignInRequired={setSignInRequired}
					price={price}
					setPrice={setPrice}
					membershipIds={membershipIds}
					setMembershipIds={setMembershipIds}
				/>
			</Section>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 24px;
	color: black;
`

export default EditDownloadablePage
