import { type FC, useId, useLayoutEffect, useState } from "react"

import { type CommunityAccess, communityAccess } from "@forento/shared/models/community"
import { getCommunityAccessDisplayName, isCommunityAccessHigher } from "@forento/shared/utilities/community"

import Button, { SubmitButton } from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import InputField from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import trpc, { query } from "~/utilities/trpc"

type Props = {
	isOpen: boolean
	channel: {
		id: number | null
		name: string
		defaultAccess: CommunityAccess | null
		membershipAccess: Record<number, CommunityAccess>
	}
	onClose(): void
	onSubmitted(): Promise<unknown>
}
const EditModal: FC<Props> = ({ isOpen, channel, onClose, onSubmitted }) => {
	const alert = useAlert()

	const defaultAccessId = useId()
	const membershipAccessId = useId()

	const [name, setName] = useState("")
	const [defaultAccess, setDefaultAccess] = useState<CommunityAccess | null>("post")
	const [membershipAccess, setMembershipAccess] = useState<Record<number, CommunityAccess>>({})
	const [isSubmitting, setSubmitting] = useState(false)

	const memberships = query.membership.list.useQuery()

	useLayoutEffect(() => {
		setName(channel.name)
		setDefaultAccess(channel.defaultAccess)
		setMembershipAccess(channel.membershipAccess)
	}, [channel])

	async function handleSubmit() {
		setSubmitting(true)
		try {
			await trpc.community.updateChannel.mutate({
				id: channel.id,
				data: { name, defaultAccess, membershipAccess },
			})
			await onSubmitted()
			onClose()
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to edit the channel. Please try again.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Edit channel</ModalTitle>
			{memberships.error ? (
				<>
					<ModalText>Failed to load data.</ModalText>
					<ModalButtons>
						<Button variant="secondary" onClick={onClose}>
							Close
						</Button>
					</ModalButtons>
				</>
			) : memberships.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					<ModalInputs>
						<InputField label="Channel name" value={name} onChange={setName} />
						<div>
							<InputLabel htmlFor={defaultAccessId}>Default user access</InputLabel>
							<Dropdown
								id={defaultAccessId}
								items={[
									{ id: "none", title: "None" },
									...communityAccess.map(access => ({
										id: access,
										title: getCommunityAccessDisplayName(access),
									})),
								]}
								selectedItemId={defaultAccess ?? "none"}
								onChange={value => setDefaultAccess(communityAccess.find(x => x === value) ?? null)}
							/>
						</div>
						{memberships.data.map(membership => (
							<div key={membership.id}>
								<InputLabel htmlFor={`${membershipAccessId}-${membership.id}`}>
									Access for {membership.title}
								</InputLabel>
								<Dropdown
									id={`${membershipAccessId}-${membership.id}`}
									items={[
										{ id: "default", title: "-- Same as default --" },
										...communityAccess
											.filter(access => isCommunityAccessHigher(access, defaultAccess))
											.map(access => ({
												id: access,
												title: getCommunityAccessDisplayName(access),
											})),
									]}
									selectedItemId={membershipAccess[membership.id] ?? "default"}
									onChange={value => {
										const access = communityAccess.find(x => x === value)
										if (access) {
											setMembershipAccess(current => ({ ...current, [membership.id]: access }))
										} else {
											setMembershipAccess(current => {
												delete current[membership.id]
												return { ...current }
											})
										}
									}}
								/>
							</div>
						))}
					</ModalInputs>
					<ModalButtons>
						<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
							Cancel
						</Button>
						<SubmitButton variant="primary" isLoading={isSubmitting}>
							Save
						</SubmitButton>
					</ModalButtons>
				</>
			)}
		</DefaultModal>
	)
}

export default EditModal
