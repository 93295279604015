import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { CloseIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import trpc from "~/utilities/trpc"

import Task from "./Task"

type Props = {
	tasks: {
		title: string
		callToAction: string
		onClick: string
		isCompleted: boolean
	}[]
	reload(): Promise<unknown>
}

const Onboarding: FC<Props> = ({ tasks, reload }) => {
	const alert = useAlert()

	async function handleDismiss() {
		const dialog = await alert.confirm("Dismiss onboarding", "Are you sure you want to dismiss the onboarding?")
		if (!dialog.result) return

		try {
			await trpc.platform.update.mutate({ isOnboardingDismissed: true })
			await reload()
			dialog.close()
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to dismiss the onboarding. Please try again later.")
		}
	}

	return (
		<Container>
			<Header>
				<Title>Onboarding</Title>
				<DismissButton onClick={handleDismiss}>
					<DismissIcon />
				</DismissButton>
			</Header>
			<List>
				{tasks.map((task, index) => (
					<Task key={index} order={index + 1} {...task} />
				))}
			</List>
		</Container>
	)
}

const Container = styled.div`
	grid-column: 1 / span 2;
	border-radius: 12px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const DismissButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const DismissIcon = styled(CloseIcon)`
	width: 24px;
	height: 24px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 16px;
`

const List = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

export default Onboarding
