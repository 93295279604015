import { useEffect, useState, type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { type PrivateSession } from "@forento/shared/models/privateSession"
import { parseNumber } from "@forento/shared/utilities/number"

import InputField, { InputTextArea, useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import usePrice from "~/hooks/usePrice"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

const EditSessionPage: FC = () => {
	const sessionId = parseNumber(useParams().sessionId)

	const { data: session, error, refetch } = query.privateSession.get.useQuery(sessionId ?? -1)

	if (session === null) return <Navigate to={routes.sessions.index()} />

	return (
		<Layout>
			<PageBreadcrumb
				title={session?.title ?? "..."}
				path={[{ title: "Sessions", link: routes.sessions.index() }]}
			/>
			{error ? (
				<p>Failed to load session.</p>
			) : session === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content session={session} reload={refetch} />
			)}
		</Layout>
	)
}

const Content: FC<{ session: PrivateSession; reload(): Promise<unknown> }> = ({ session, reload }) => {
	const toast = useToast()
	const alert = useAlert()

	const [title, setTitle] = useState(session.title)
	const [shortDescription, setShortDescription] = useState(session.shortDescription ?? "")
	const longDescription = useRichTextArea({ initialValue: session.longDescription, label: "Description" })
	const { price, priceValue, setPrice } = usePrice(session.price?.amount.toString() ?? "")
	const [isSignInRequired, setSignInRequired] = useState(session.isSignInRequired)
	const [membershipIds, setMembershipIds] = useState(session.membershipIds)
	const [redemptionInstructions, setRedemptionInstructions] = useState(session.redemptionInstructions ?? "")
	const [redemptionLink, setRedemptionLink] = useState(session.redemptionLink ?? "")

	useEffect(() => {
		const isModified =
			title !== session.title ||
			shortDescription !== (session.shortDescription ?? "") ||
			longDescription.isModified ||
			priceValue !== (session.price?.amount ?? null) ||
			isSignInRequired !== session.isSignInRequired ||
			membershipIds.join(",") !== session.membershipIds.join(",") ||
			redemptionInstructions !== (session.redemptionInstructions ?? "") ||
			redemptionLink !== (session.redemptionLink ?? "")

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				try {
					if (title.trim().length === 0) {
						await alert.show("Error", "You must fill out all required fields.")
						return false
					}
					if (redemptionInstructions.trim().length === 0 && redemptionLink.trim().length === 0) {
						await alert.show(
							"Error",
							"You must provide either redemption instructions or a redemption link.",
						)
						return false
					}
					await trpc.privateSession.edit.mutate({
						id: session.id,
						data: {
							title,
							shortDescription,
							longDescription: longDescription.exportEditorState(),
							price: priceValue,
							isSignInRequired,
							membershipIds,
							redemptionInstructions:
								redemptionInstructions.trim().length > 0 ? redemptionInstructions : null,
							redemptionLink: redemptionLink.trim().length > 0 ? redemptionLink : null,
						},
					})
					await reload()
					return true
				} catch (error) {
					console.error(error)
					await alert.show("Error", "Failed to save changes. Please try again later.")
					return false
				}
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [
		alert,
		isSignInRequired,
		longDescription,
		longDescription.isModified,
		membershipIds,
		priceValue,
		redemptionInstructions,
		redemptionLink,
		reload,
		session.id,
		session.isSignInRequired,
		session.membershipIds,
		session.price?.amount,
		session.redemptionInstructions,
		session.redemptionLink,
		session.shortDescription,
		session.title,
		shortDescription,
		title,
		toast,
	])

	return (
		<Container>
			<InputField label="Title *" value={title} onChange={setTitle} />
			<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
			{longDescription.element}
			<InputTextArea
				label="Redemption instructions"
				value={redemptionInstructions}
				onChange={setRedemptionInstructions}
			/>
			<InputField
				label="Redemption link"
				value={redemptionLink}
				onChange={setRedemptionLink}
				placeholder="Calendar booking, etc."
			/>
			<InputLabel>Pricing</InputLabel>
			<ProductPricing
				type="session"
				price={price}
				setPrice={setPrice}
				isSignInRequired={isSignInRequired}
				setSignInRequired={setSignInRequired}
				membershipIds={membershipIds}
				setMembershipIds={setMembershipIds}
			/>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default EditSessionPage
