import { type Dispatch, type FC, type SetStateAction, useMemo, useState } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { toggleStateArray } from "@forento/shared/utilities/array"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import { CheckmarkIcon, CloseIcon, SettingsIcon } from "~/components/Icon"
import ImageUploader from "~/components/ImageUploader"
import InputField, { InputTextArea } from "~/components/InputField"
import ManageTagsModal from "~/components/ManageTagsModal"
import { lightTextColor, primaryColor } from "~/utilities/styles"

import { Description, Title } from "./styles"

type Props = {
	title: string
	shortDescription: string
	commentsEnabled: boolean
	autoApproveComments: boolean
	selectedTags: number[]
	thumbnail: File | null
	setTitle: Dispatch<SetStateAction<string>>
	setShortDescription: Dispatch<SetStateAction<string>>
	setCommentsEnabled: Dispatch<SetStateAction<boolean>>
	setAutoApproveComments: Dispatch<SetStateAction<boolean>>
	setSelectedTags: Dispatch<SetStateAction<number[]>>
	setThumbnail: Dispatch<SetStateAction<File | null>>
	setThumbnailUpdated: Dispatch<SetStateAction<boolean>>
	tags: { id: number; label: string }[]
	reloadTags(): void
}
const CourseInformation: FC<Props> = ({
	title,
	shortDescription,
	commentsEnabled,
	autoApproveComments,
	selectedTags,
	thumbnail,
	setTitle,
	setShortDescription,
	setCommentsEnabled,
	setAutoApproveComments,
	setSelectedTags,
	setThumbnail,
	setThumbnailUpdated,
	tags,
	reloadTags,
}) => {
	const [isManageTagsModalOpen, setManageTagsModalOpen] = useState(false)

	const thumbnailFilePath = useMemo(() => (thumbnail ? URL.createObjectURL(thumbnail) : null), [thumbnail])

	return (
		<>
			<ManageTagsModal
				type="course"
				isOpen={isManageTagsModalOpen}
				tags={tags}
				onClose={() => setManageTagsModalOpen(false)}
				onChange={reloadTags}
			/>
			<Title>Thumbnail</Title>
			<Description>
				A picture or social icon, used as thumbnail when you share your course on social medias.
			</Description>
			<ImageUploader
				filePath={thumbnailFilePath}
				onUpload={file => {
					setThumbnail(file)
					setThumbnailUpdated(true)
				}}
				onDelete={() => {
					setThumbnail(null)
					setThumbnailUpdated(true)
				}}
			/>
			<Title>Basic information</Title>
			<Description>Information on how to discover your course online.</Description>
			<Inputs>
				<InputField label="Title" value={title} onChange={setTitle} />
				<InputTextArea label="Description" value={shortDescription} onChange={setShortDescription} />
				<Checkbox label="Enable comments" isChecked={commentsEnabled} onChange={setCommentsEnabled} />
				<Checkbox
					label="Automatically approve comments"
					isChecked={autoApproveComments}
					onChange={setAutoApproveComments}
					isDisabled={!commentsEnabled}
				/>
			</Inputs>
			<Header>
				<Title>Tags</Title>
				<Tooltip tooltip="Manage tags">
					<TagSettingsButton onClick={() => setManageTagsModalOpen(true)}>
						<SettingsIcon />
					</TagSettingsButton>
				</Tooltip>
			</Header>
			<Description>Tags help students find your course when they search for specific topics.</Description>
			<Tags>
				{tags.map(tag => {
					const isSelected = selectedTags.includes(tag.id)
					return (
						<Tag
							key={tag.id}
							$isSelected={isSelected}
							onClick={() => toggleStateArray(setSelectedTags, tag.id)}
						>
							<TagIcon as={isSelected ? CheckmarkIcon : CloseIcon} /> {tag.label}
						</Tag>
					)
				})}
			</Tags>
		</>
	)
}

const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 10px;

	${Title} {
		margin: 0;
	}
`

const TagSettingsButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`

const Tag = styled(Button)<{ $isSelected: boolean }>`
	padding: 8px 16px;
	border-radius: 36px;
	line-height: 1;
	color: white;
	background-color: ${props => (props.$isSelected ? primaryColor : lightTextColor)};
	display: flex;
	align-items: center;
	gap: 8px;
`

const TagIcon = styled.div`
	width: 20px;
	height: 20px;
`

export default CourseInformation
