import Decimal from "decimal.js"
import { useEffect, useLayoutEffect, useState, type FC } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import { type BundleProductType, type DetailedBundle } from "@forento/shared/models/bundle"
import { getDataUrlByFile, getFileNameFromPath } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import FileChooser from "~/components/FileChooser"
import { AddCircleIcon, RemoveCircleIcon } from "~/components/Icon"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { usePlatform } from "~/contexts/UserContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import usePrice from "~/hooks/usePrice"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

const EditBundlePage: FC = () => {
	const bundleId = parseNumber(useParams().bundleId)

	const { data, error, refetch } = query.bundle.get.useQuery(bundleId ?? -1)
	const courses = query.course.list.useQuery()
	const downloadables = query.downloadable.list.useQuery()
	const videos = query.video.list.useQuery()
	const sessions = query.privateSession.list.useQuery()
	const events = query.event.list.useQuery()

	if (data === null) {
		return <Navigate to={routes.bundle.index()} replace />
	}

	return (
		<Layout>
			<PageBreadcrumb path={[{ title: "Bundles", link: routes.bundle.index() }]} title={data?.name ?? "..."} />
			{error || courses.error || downloadables.error || videos.error || sessions.error || events.error ? (
				<p>Failed to load bundle.</p>
			) : !data || !courses.data || !downloadables.data || !videos.data || !sessions.data || !events.data ? (
				<PartialLoadingPage />
			) : (
				<Content
					bundle={data}
					reload={refetch}
					itemsToAdd={[
						{
							name: "Courses",
							type: "course",
							items: courses.data
								.filter(x => x.price)
								.map(x => ({ id: x.id, name: x.title, price: x.price!.amount })),
						},
						{
							name: "Videos",
							type: "video",
							items: videos.data
								.filter(x => x.price)
								.map(x => ({ id: x.id, name: x.title, price: x.price!.amount })),
						},
						{
							name: "Downloadables",
							type: "downloadable",
							items: downloadables.data
								.filter(x => x.price)
								.map(x => ({ id: x.id, name: x.title, price: x.price!.amount })),
						},
						{
							name: "Sessions",
							type: "session",
							items: sessions.data
								.filter(x => x.price)
								.map(x => ({ id: x.id, name: x.title, price: x.price!.amount })),
						},
						{
							name: "Events",
							type: "event",
							items: events.data
								.filter(x => x.price && x.startDate > new Date())
								.map(x => ({ id: x.id, name: x.title, price: x.price!.amount })),
						},
					]}
				/>
			)}
		</Layout>
	)
}

type ContentProps = {
	bundle: DetailedBundle
	itemsToAdd: { name: string; type: BundleProductType; items: { id: number; name: string; price: number }[] }[]
	reload(): Promise<unknown>
}
const Content: FC<ContentProps> = ({ bundle, itemsToAdd, reload }) => {
	const platform = usePlatform()!
	const alert = useAlert()
	const navigate = useNavigate()
	const toast = useToast()

	const [name, setName] = useState(bundle.name)
	const { price, priceValue, setPrice } = usePrice(bundle.price.amount)
	const [thumbnail, setThumbnail] = useState<File | null>(null)
	const [isThumbnailChanged, setThumbnailChanged] = useState(false)
	const [shortDescription, setShortDescription] = useState(bundle.shortDescription ?? "")
	const longDescription = useRichTextArea({ label: "Long description", initialValue: bundle.longDescription })
	const [addedItems, setAddedItems] = useState<
		{ id: number; type: BundleProductType; name: string; price: number }[]
	>([])

	useLayoutEffect(() => {
		setName(bundle.name)
		setPrice(bundle.price.amount.toString())
		setShortDescription(bundle.shortDescription ?? "")
		setAddedItems([])
		setThumbnail(null)
		setThumbnailChanged(false)
	}, [bundle, setPrice])

	useEffect(() => {
		const abortController = new AbortController()

		if (bundle.thumbnailFilePath) {
			fetch(bundle.thumbnailFilePath, { signal: abortController.signal })
				.then(async x => ({
					name: getFileNameFromPath(x.url),
					blob: await x.blob(),
				}))
				.then(({ name, blob }) => {
					if (abortController.signal.aborted) return
					setThumbnail(new File([blob], name ?? "Thumbnail", { type: blob.type }))
				})
		} else {
			setThumbnail(null)
		}

		return () => {
			abortController.abort()
		}
	}, [bundle.thumbnailFilePath])

	useEffect(() => {
		const isModified =
			name !== bundle.name ||
			priceValue !== bundle.price.amount ||
			shortDescription !== (bundle.shortDescription ?? "") ||
			longDescription.isModified ||
			isThumbnailChanged ||
			addedItems.length > 0

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				if (name.trim().length === 0 || priceValue === null) {
					await alert.show("Error", "Please fill in all required fields.")
					return false
				}

				const contentPrice = [...bundle.items, ...addedItems].reduce(
					(value, current) => value.add(current.price),
					new Decimal(0),
				)
				if (priceValue >= contentPrice.toNumber()) {
					await alert.show("Error", "Price should be less than the sum of the prices of the included items.")
					return false
				}

				try {
					await trpc.bundle.update.mutate({
						id: bundle.id,
						data: {
							name,
							thumbnailDataUrl: thumbnail ? await getDataUrlByFile(thumbnail) : null,
							shortDescription,
							longDescription: longDescription.exportEditorState(),
							price: priceValue,
							addedItems: addedItems.map(x => ({ id: x.id, type: x.type })),
						},
					})
					await reload()
					return true
				} catch (error) {
					console.error(error)
					await alert.show("Error", "Failed to save bundle.")
				}

				return false
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [
		addedItems,
		addedItems.length,
		alert,
		bundle,
		isThumbnailChanged,
		longDescription,
		name,
		navigate,
		priceValue,
		reload,
		setPrice,
		shortDescription,
		thumbnail,
		toast,
	])

	return (
		<Container>
			<InputField label="Name" value={name} onChange={setName} />
			<InputField label={`Price (${platform.currency})`} value={price} onChange={setPrice} />
			<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
			{longDescription.element}
			<div>
				<InputLabel>Thumbnail (optional)</InputLabel>
				<FileChooser
					type="file"
					value={thumbnail}
					onChange={value => {
						setThumbnailChanged(true)
						setThumbnail(value)
					}}
				/>
			</div>
			<Subtitle>Included products</Subtitle>
			<IncludedProducts>
				{itemsToAdd.map(category => (
					<ContentCategory key={category.name}>
						<ContentCategoryTitle>{category.name}</ContentCategoryTitle>
						<ContentsList>
							{[
								...bundle.items.map(x => ({ id: x.id, type: x.type, name: x.name, isAdded: false })),
								...addedItems.map(x => ({ id: x.id, type: x.type, name: x.name, isAdded: true })),
							]
								.filter(added => added.type === category.type)
								.map(item => (
									<ContentItem key={item.id + (item.isAdded ? "-added" : "")}>
										{item.name}
										{item.isAdded && (
											<ContentItemButton
												onClick={() =>
													setAddedItems(current =>
														current.filter(
															x => !(x.type === item.type && x.id === item.id),
														),
													)
												}
											>
												<ContentItemIcon as={RemoveCircleIcon} />
											</ContentItemButton>
										)}
									</ContentItem>
								))}
						</ContentsList>
					</ContentCategory>
				))}
			</IncludedProducts>
			<Subtitle>Products to add</Subtitle>
			<IncludedProducts>
				{itemsToAdd.map(category => (
					<ContentCategory key={category.name}>
						<ContentCategoryTitle>{category.name}</ContentCategoryTitle>
						<ContentsList>
							{category.items
								.filter(
									item =>
										![...bundle.items, ...addedItems].find(
											x => x.type === category.type && x.id === item.id,
										),
								)
								.map(item => (
									<ContentItem key={item.id}>
										{item.name}
										<ContentItemButton
											onClick={() =>
												setAddedItems(current => [
													...current,
													{
														id: item.id,
														type: category.type,
														name: item.name,
														price: item.price,
													},
												])
											}
										>
											<ContentItemIcon as={AddCircleIcon} />
										</ContentItemButton>
									</ContentItem>
								))}
						</ContentsList>
					</ContentCategory>
				))}
			</IncludedProducts>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Subtitle = styled.h2`
	font-size: 24px;
	font-weight: 600;
	margin-top: 16px;
`

const IncludedProducts = styled.div`
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
`

const ContentCategory = styled.div`
	flex: 1 0 200px;
`

const ContentCategoryTitle = styled.h3`
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 8px;
`

const ContentsList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const ContentItem = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 8px 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
`

const ContentItemButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const ContentItemIcon = styled.div`
	width: 100%;
	height: 100%;
`

export default withAccessRequirement(
	"bundles",
	<PageBreadcrumb path={[{ title: "Bundles", link: routes.bundle.index() }]} title="..." />,
	EditBundlePage,
)
