import { useState } from "react"

import Button from "~/components/Button"
import Layout, { PageHeader, PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import useProductPublishedAlert from "~/hooks/useProductPublishedAlert"
import { query } from "~/utilities/trpc"

import CreateEventModal from "./CreateEventModal"
import Events from "./Events"

const EventsPage: React.FC = () => {
	const publishedAlert = useProductPublishedAlert("event")

	const { data: events, error, refetch } = query.event.list.useQuery()

	const [isCreateEventModalOpen, setCreateEventModalOpen] = useState(false)

	return (
		<Layout>
			<CreateEventModal
				isOpen={isCreateEventModalOpen}
				onClose={() => {
					setCreateEventModalOpen(false)
				}}
				onCreated={async createdId => {
					setCreateEventModalOpen(false)
					await refetch()
					await publishedAlert.show({ itemId: createdId })
				}}
			/>
			<PageHeader>
				<PageTitle>Events</PageTitle>
				<Button variant="primary" onClick={() => setCreateEventModalOpen(true)}>
					Create event
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load events.</p>
			) : events === undefined ? (
				<PartialLoadingPage />
			) : (
				<Events events={events} reloadEvents={refetch} />
			)}
		</Layout>
	)
}

export default EventsPage
