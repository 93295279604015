import { type OutputData } from "@editorjs/editorjs"
import { type FC, lazy, Suspense, useEffect, useMemo, useState } from "react"

import Button from "~/components/Button"
import InputField from "~/components/InputField"
import PartialLoadingPage from "~/components/PartialLoadingPage"

import Attachments from "./Attachments"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow } from "./styles"

const EditorJs = lazy(() => import("~/components/EditorJs"))

type Props = {
	page: {
		id: number
		title: string
		text: string | null
		attachments: { id: number; fileType: string; label: string; url: string }[]
		links: { id: number; label: string; url: string }[]
	}
	onClose(): void
	onSavePage(data: { title: string; text: string | null }): void
	onDeletePage(): void
}

const EditCustomPageModalContent: FC<Props> = ({ page, onClose, onSavePage, onDeletePage }) => {
	const initialContent = useMemo(() => (page.text != null ? JSON.parse(page.text) : null), [page.text])

	const [title, setTitle] = useState(page.title)
	const [text, setText] = useState<{ isModified: boolean; value: OutputData }>({
		isModified: false,
		value: initialContent,
	})

	useEffect(() => {
		setTitle(page.title)
		setText({ isModified: false, value: initialContent })
	}, [page.title, initialContent])

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			<Suspense fallback={<PartialLoadingPage />}>
				<EditorJs placeholder="Enter page content here..." initialValue={initialContent} setValue={setText} />
			</Suspense>
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0}
						onClick={() => onSavePage({ title, text: JSON.stringify(text.value) })}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

export default EditCustomPageModalContent
