import { TRPCClientError, createTRPCProxyClient, httpBatchLink, httpLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"

import trpcTransformer from "@forento/shared/utilities/trpcTransformer"

import type { AppRoutes } from "../../../api/src/routes"

const trpcOptions = {
	links: [
		CONFIG.isDevelopment
			? httpLink({ url: "/api/_" })
			: httpBatchLink({
					url: "https://api.forento.io/_",
					fetch(url, options) {
						return fetch(url, { ...options, credentials: "include" })
					},
				}),
	],
	transformer: trpcTransformer,
}

const trpc = createTRPCProxyClient<AppRoutes>(trpcOptions)

export const query = createTRPCReact<AppRoutes>()

export const queryTrpcClient = query.createClient(trpcOptions)

function isTRPCClientError(cause: unknown): cause is TRPCClientError<AppRoutes> {
	return cause instanceof TRPCClientError
}

export function getInputErrors(error: unknown): Record<string, string[]> {
	if (!isTRPCClientError(error)) return {}
	return error.data?.inputErrors ?? {}
}

export default trpc
