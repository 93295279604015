import { type FC, useState } from "react"
import styled, { css } from "styled-components"

import PartialLoadingPage from "~/components/PartialLoadingPage"
import { query } from "~/utilities/trpc"

import ChannelSelector from "./ChannelSelector"
import CreatePost from "./CreatePost"
import Post from "./Post"
import PostDetail from "./PostDetail"

const CommunityPosts: FC = () => {
	const [selectedChannelId, setSelectedChannelId] = useState<number | null>(null)
	const [selectedPostId, setSelectedPostId] = useState<number>()

	const channels = query.community.listChannels.useQuery()
	const posts = query.community.listPersonalizedPostsByChannel.useQuery(selectedChannelId)

	if (channels.error || posts.error) return <p>Failed to load posts.</p>
	if (channels.data === undefined) return <PartialLoadingPage />

	const currentChannel = channels.data.find(channel => channel.id === selectedChannelId)!

	return (
		<Container $includeCreatePost>
			{channels.data.length > 1 && (
				<ChannelSelector
					channels={channels.data}
					selectedChannelId={selectedChannelId}
					setSelectedChannelId={setSelectedChannelId}
				/>
			)}
			<CreatePost channel={currentChannel} onPostCreated={posts.refetch} />
			{posts.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Posts>
					{[...posts.data]
						.sort((a, b) => b.createDate.getTime() - a.createDate.getTime())
						.map(post => (
							<Post
								key={post.id}
								post={post}
								isSelected={selectedPostId === post.id}
								onSelect={() => setSelectedPostId(post.id)}
								onChange={posts.refetch}
							/>
						))}
				</Posts>
			)}
			<PostDetail postId={selectedPostId ?? null} />
		</Container>
	)
}

export const ProfileCommunityPosts: FC<{ profileId: number; gridArea: string }> = ({ profileId, gridArea }) => {
	const { data: posts, error, refetch } = query.community.listPersonalizedPostsByProfile.useQuery(profileId)

	const [selectedPostId, setSelectedPostId] = useState<number>()

	if (error) return <p>Failed to load posts.</p>
	if (posts === undefined) return <PartialLoadingPage />

	return (
		<Container $gridArea={gridArea}>
			<Posts>
				{[...posts]
					.sort((a, b) => b.createDate.getTime() - a.createDate.getTime())
					.map(post => (
						<Post
							key={post.id}
							post={post}
							isSelected={selectedPostId === post.id}
							onSelect={() => setSelectedPostId(post.id)}
							onChange={refetch}
							showChannel
						/>
					))}
			</Posts>
			<PostDetail postId={selectedPostId ?? null} />
		</Container>
	)
}

const Container = styled.div<{ $gridArea?: string; $includeCreatePost?: boolean }>`
	${props =>
		props.$gridArea !== undefined &&
		css`
			grid-area: ${props.$gridArea};
		`}
	display: grid;
	${props =>
		props.$includeCreatePost
			? css`
					grid-template-areas: "channels post-detail" "create-post post-detail" "posts post-detail";
					grid-template-rows: auto 1fr;
				`
			: css`
					grid-template-areas: "posts post-detail";
					grid-template-rows: 1fr;
				`}
	grid-template-columns: 1fr 400px;
	gap: 24px;
`

const Posts = styled.div`
	grid-area: posts;
	flex: 2 0 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
`

export default CommunityPosts
