import { useState } from "react"
import { Navigate } from "react-router"
import styled from "styled-components"

import { type CardBrand } from "@forento/shared/models/payment"
import { getCardBrandDisplayName } from "@forento/shared/utilities/payment"

import Button from "~/components/Button"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import americanExpressImage from "./images/americanExpress.png"
import dinersImage from "./images/diners.svg"
import discoverImage from "./images/discover.svg"
import jcbImage from "./images/jcb.svg"
import mastercardImage from "./images/mastercard.svg"
import unionPayImage from "./images/unionPay.svg"
import unknownImage from "./images/unknown.svg"
import visaImage from "./images/visa.png"

const PaymentMethod: React.FC = () => {
	const platform = usePlatform()!

	const [isSubmittingChangePaymentMethod, setSubmittingChangePaymentMethod] = useState(false)

	const { data: paymentMethod, error } = query.payment.creator.getCurrentPaymentMethod.useQuery()

	if (error) return <div>Failed to load payment method.</div>
	if (paymentMethod === undefined) return <PartialLoadingPage />

	const handleUpdatePaymentMethod = async () => {
		setSubmittingChangePaymentMethod(true)
		try {
			const { redirectUrl } = await trpc.payment.stripe.updatePaymentMethod.mutate()
			window.location.href = redirectUrl
		} finally {
			setSubmittingChangePaymentMethod(false)
		}
	}

	if (platform.stripeCustomerId === null) {
		return <Navigate to={routes.settings.billing.plans()} replace />
	}

	if (paymentMethod === null) return null

	return (
		<Container>
			<Content>
				<Brand>
					<BrandImage src={getCardBrandImageUrl(paymentMethod.brand)} />
				</Brand>
				<div>
					<Title>
						{getCardBrandDisplayName(paymentMethod.brand)} ending in {paymentMethod.last4}
					</Title>
					<Expiration>
						Expires {paymentMethod.expiration.month.toString().padStart(2, "0")}/
						{paymentMethod.expiration.year}
					</Expiration>
				</div>
			</Content>
			<StyledButton
				variant="primary"
				onClick={handleUpdatePaymentMethod}
				isLoading={isSubmittingChangePaymentMethod}
			>
				Change payment method
			</StyledButton>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const StyledButton = styled(Button).attrs({ variant: "primary" })`
	align-self: flex-start;
`

const getCardBrandImageUrl = (brand: CardBrand | null): string => {
	switch (brand) {
		case "visa":
			return visaImage
		case "mastercard":
			return mastercardImage
		case "amex":
			return americanExpressImage
		case "discover":
			return discoverImage
		case "diners":
			return dinersImage
		case "unionpay":
			return unionPayImage
		case "jcb":
			return jcbImage
		default:
			return unknownImage
	}
}

const Content = styled.div`
	background-color: #f9f5ff;
	border: 1px solid #9b89d0;
	border-radius: 8px;
	padding: 16px;
	display: flex;
	gap: 16px;
`

const Brand = styled.div`
	flex: 0 0 auto;
	width: 46px;
	height: 32px;
	background-color: #ffffff;
	border: 1px solid #f2f4f7;
	border-radius: 6px;
	padding: 6px;
`

const BrandImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

const Title = styled.h3`
	font-weight: 600;
	font-size: 16px;
	color: #53389e;
	margin-bottom: 2px;
`

const Expiration = styled.p`
	font-size: 16px;
	color: #7f56d9;
`

export default PaymentMethod
