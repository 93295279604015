import styled from "styled-components"

import Button from "~/components/Button"
import { MessageIcon } from "~/components/Icon"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const NewConversationModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
	const profileId = useUser().user!.communityProfileId!

	const profiles = query.community.listProfiles.useQuery(undefined, { enabled: isOpen })
	const conversations = query.community.listConversations.useQuery(undefined, { enabled: isOpen })

	return (
		<DefaultModal isOpen={isOpen} width={1000}>
			<ModalTitle>New conversation</ModalTitle>

			{profiles.error || conversations.error ? (
				<p>Failed to load profiles.</p>
			) : !profiles.data || !conversations.data ? (
				<PartialLoadingPage />
			) : (
				<ModalContentPadding>
					<Table
						isCompact
						columns={["Name", "E-mail", "Start conversation"]}
						data={profiles.data
							?.filter(
								p => profileId !== p.id && !conversations.data!.some(c => p.id === c.otherProfile.id),
							)
							.map(profile => [
								`${profile.firstName} ${profile.lastName}`,
								profile.email,
								<MessageButton onClick={routes.community.message(profile.id)}>
									<MessageIcon />
								</MessageButton>,
							])}
					/>
				</ModalContentPadding>
			)}
			<ModalButtons>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

const MessageButton = styled(Button)`
	width: 24px;
	height: 24px;
`

export default NewConversationModal
